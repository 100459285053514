import { combineReducers } from 'redux'

import userReducer from './userReducer'
import SpinnerReducer from './spinner.reducer'

const RootReducer = combineReducers({
    userDetails : userReducer,
    spinner: SpinnerReducer, 

});
export default RootReducer;