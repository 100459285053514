import React, { useContext } from 'react'
import appContext from '../../context/app.context'

export const DashboardPage: React.FC = () => {
  const { setSpinnerVisibility } = useContext(appContext)
  setSpinnerVisibility(false)
  return (
    <div className="listed-product-layout">
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>Dashboard </p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <div className="m-auto">No Data Available</div>
        </div>
      </div>
    </div>
  )
}
