import { AxiosError } from 'axios'
import { store } from 'redux/store'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'
import { LOGIN, USER, USERUPDATE } from 'redux/actions/UserActionTypes'

import { BaseService } from './base.service'
import { ISuccessResponse, IErrorResponse } from './interfaces/ApiResponse.interface'
import { ILoginCredentials, IResetPassword, IUserDetails } from './interfaces/login.interface'

export class LoginService extends BaseService {
  public async login(credentials: ILoginCredentials): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.LOGIN, credentials)

      localStorage.setItem('accessToken', data.data.jwtToken)

      // let result: string[] = []
      // // rolesPersmission.map((item) => {
      // //   const permission = _.keys(data.data.Permission[item]).filter(
      // //     (element: string): void => data.data.Permission[item][element]
      // //   )
      // //   result = [...result, ...permission]?.filter((v) => v !== '_id')

      // //   return null
      // // })

      // // data.data.Permission = result
      store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      localStorage.setItem('userMail', data.data.email)
      localStorage.setItem('role', data.data.role)


      const role: string | null = data.data.role
      const isAdmin: boolean = role === 'admin' || role === 'Super Admin'

      isAdmin ? localStorage.setItem('name', data.data.userName) : localStorage.setItem('name', data.legalName)

      return data
    } catch (error: any) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async ssoLogin(token: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.SSO_LOGIN, {
        headers: {
          Authorization: token
        }
      })


      localStorage.setItem('accessToken', data.data.jwtToken)

      // let result: string[] = []
      // // rolesPersmission.map((item) => {
      // //   const permission = _.keys(data.data.Permission[item]).filter(
      // //     (element: string): void => data.data.Permission[item][element]
      // //   )
      // //   result = [...result, ...permission]?.filter((v) => v !== '_id')

      // //   return null
      // // })

      // // data.data.Permission = result
      store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      localStorage.setItem('userMail', data.data.email)
      localStorage.setItem('role', data.data.role)

      const role: string | null = data.data.role

      const isAdmin: boolean = role === 'Admin' || role === 'Super Admin'
      isAdmin ? localStorage.setItem('name', data.data.userName) : localStorage.setItem('name', data.legalName)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getuserDetails(email: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_USER_DETAILS}/${email}`)
      store.dispatch({
        type: USER,
        payload: data.data
      })
      
      localStorage.setItem('userMail', data.data.email)
      localStorage.setItem('role', data.data.role)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateUserDetails(fields: IUserDetails): Promise<ISuccessResponse | IErrorResponse> {
    const userEmail = localStorage.getItem('userMail') || ''
    const token = localStorage.getItem('accessToken') || ''
    fields.email = userEmail

    try {
      const { data } = await this.httpClient.put(`${API_ENDPOINTS.UPDATE_USER_DETAILS}`, fields, {
        headers: { Authorization: token }
      })
      store.dispatch({
        type: USERUPDATE,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async register(userDetails: IUserDetails): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_USER_DETAILS, userDetails)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async forgotPassword(email: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.FORGOT_PASSWORD, { email })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getActivationLink(email: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.RESEND_ACTIVATION_LINK, { email })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async resetPassword(resetPasswordData: IResetPassword): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.RESET_PASSWORD, {
        token: resetPasswordData.token,
        password: resetPasswordData.password
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async activateEmail(token: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.ACTIVATE_EMAIL, { headers: { authorization: token } })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
