import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: 'https://auth.poorvika.com/auth/',
  realm: 'poorvika',
  clientId: 'Review',

}); 

export default keycloak;
