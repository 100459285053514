import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

type Tprops = {
    reviewEdits?: any
    onClick?: () => void
    handleCloseUpdated?: () => void
}

const TemplateHistoryView: React.FC<Tprops> = ({ reviewEdits }) => {

    const resultdata: any = {};
    const demodemo = reviewEdits.oldData.ratings.filter((item: any) => item.name === "oneStar" || item.name === "twoStar")
    demodemo[0].ratingFields.map((pair: any) => {
        resultdata[`oneStar`] = pair.field
    });

    const resultdata1: any = {};
    const demodemo1 = reviewEdits.newData.ratings.filter((item: any) => item.name === "oneStar" || item.name === "twoStar")
    demodemo1[0].ratingFields.map((pair: any) => {
        resultdata1[`oneStar`] = pair.field
    });

    const resultdata2: any = {};
    const demodemo2 = reviewEdits.oldData.ratings.filter((item: any) => item.name === "twoStar")
    demodemo2[0].ratingFields.map((pair: any) => {
        resultdata2[`twoStar`] = pair.field
    });

    const resultdata22: any = {};
    const demodemo22 = reviewEdits.newData.ratings.filter((item: any) => item.name === "twoStar")
    demodemo22[0].ratingFields.map((pair: any) => {
        resultdata22[`twoStar`] = pair.field
    });
    const resultdata3: any = {};
    const demodemo3 = reviewEdits.oldData.ratings.filter((item: any) => item.name === "threeStar")
    demodemo3[0].ratingFields.map((pair: any) => {
        resultdata3[`threeStar`] = pair.field
    });

    const resultdata33: any = {};
    const demodemo33 = reviewEdits.newData.ratings.filter((item: any) => item.name === "threeStar")
    demodemo33[0].ratingFields.map((pair: any) => {
        resultdata33[`threeStar`] = pair.field
    });
    const resultdata4: any = {};
    const demodemo4 = reviewEdits.oldData.ratings.filter((item: any) => item.name === "fourStar")
    demodemo4[0].ratingFields.map((pair: any) => {
        resultdata4[`fourStar`] = pair.field
    });

    const resultdata44: any = {};
    const demodemo44 = reviewEdits.newData.ratings.filter((item: any) => item.name === "fourStar")
    demodemo44[0].ratingFields.map((pair: any) => {
        resultdata44[`fourStar`] = pair.field
    });
    const resultdata5: any = {};
    const demodemo5 = reviewEdits.oldData.ratings.filter((item: any) => item.name === "fiveStar")
    demodemo5[0].ratingFields.map((pair: any) => {
        resultdata5[`fiveStar`] = pair.field
    });

    const resultdata55: any = {};
    const demodemo55 = reviewEdits.newData.ratings.filter((item: any) => item.name === "fiveStar")
    demodemo55[0].ratingFields.map((pair: any) => {
        resultdata55[`fiveStar`] = pair.field
    });
    const newdata = reviewEdits.newData;
    const olddata = reviewEdits.oldData;
    function compareJSONObjects(obj1: any, obj2: any): any[] {
        const arr: any[] = [];
        for (const prop in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
                const myobj: any = {};
                const value1 = obj1[prop];
                const value2 = obj2[prop];
                if (typeof value1 === "object" && typeof value2 === "object") {
                    compareJSONObjects(value1, value2);
                } else if (value1 !== value2) {
                    myobj['Properties'] = prop;
                    myobj["current-" + prop] = value1;
                    myobj["previous-" + prop] = value2;
                    arr.push(myobj);
                }

            }
        }
        return arr;
    }

    // function ArrayComparison(array1: any[], array2: any[]): void {
    //     array1.forEach((value, index) => {
    //         if ((typeof value !== "string") && (array2[index] !== undefined)) {
    //             console.log(array2[index], 'index')
    //             compareJSONObjects(value, array2[index]);
    //         }
    //     })
    // }
    const result: any = compareJSONObjects(newdata, olddata);
    const result1 = compareJSONObjects(resultdata1, resultdata)
    const result2 = compareJSONObjects(resultdata22, resultdata2)
    const result3 = compareJSONObjects(resultdata33, resultdata3)
    const result4 = compareJSONObjects(resultdata44, resultdata4)
    const result5 = compareJSONObjects(resultdata55, resultdata5)

    // if (prop === '_id')
    // return []; 
    var styles: any = result.map((item: any) => item.Properties);
    var styles1: any = result1.map((item: any) => item.Properties);
    var styles2: any = result2.map((item: any) => item.Properties);
    var styles3: any = result3.map((item: any) => item.Properties);
    var styles4: any = result4.map((item: any) => item.Properties);
    var styles5: any = result5.map((item: any) => item.Properties);
    const [value, setValue] = useState('');

    return (
        <div className="listed-product-layoutHistory">
            <div className="Inventory-table-layoutHistory">
                <div className="Inventory-table-headerHistory">
                    <div className="listed-products-selectorHistory">
                        <p>Template History</p>
                    </div>
                </div>
                <div className="Inventory-table-bodyHistory">
                    <table className="inventory-products-tableHistory">
                        <thead>
                            <tr>
                                <th style={{ display: 'flex', justifyContent: 'start' }}>Fields Name</th>
                                <th>Previous Data</th>
                                <th>Current Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p className='textfont'>Status</p>
                                </td>
                                <td style={styles.includes('status') ? { color: styles.includes('status') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('status') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.status === true ? "Enable" : "Disable"}
                                </td>
                                <td style={styles.includes('status') ? { color: styles.includes('status') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('status') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.status === true ? "Enable" : "Disable"}
                                </td>
                            </tr>
                            <tr className="user-list-tbodyHistory">
                                <td>
                                    <p className='textfont'>Template Name</p>
                                </td>
                                <td style={styles.includes('templateName') ? { color: styles.includes('templateName') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('templateName') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.templateName}
                                </td>
                                <td style={styles.includes('templateName') ? { color: styles.includes('templateName') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('templateName') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.templateName}
                                </td>
                            </tr>
                            <tr className="user-list-tbodyHistory">
                                <td className='textfont' style={{ display: 'flex' }}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label" style={{ textAlign: 'start', fontSize: '15px', fontWeight: 700, margin: '0px' }}>Ratings</FormLabel>
                                        <RadioGroup
                                            defaultValue="Onestar"
                                            name="radio-buttons-group"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            <FormControlLabel value="Onestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Onestar</span>} />
                                            <FormControlLabel value="Twostar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Twostar</span>} />
                                            <FormControlLabel value="Threestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Threestar</span>} />
                                            <FormControlLabel value="Fourstar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Fourstar</span>} />
                                            <FormControlLabel value="Fivestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Fivestar</span>} />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                                <td>
                                    {reviewEdits.oldData.ratings.map((data: any) => {
                                        switch (value) {
                                            case 'Onestar':
                                                return data.name === 'oneStar' ? data.ratingFields.map((item: any) => <p style={styles1.includes('oneStar') ? { color: styles1.includes('oneStar') ? "red" : "gray", fontWeight: 700 } : { color: styles1.includes('oneStar') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Twostar':
                                                return data.name === 'twoStar' ? data.ratingFields.map((item: any) => <p style={styles2.includes('twoStar') ? { color: styles2.includes('twoStar') ? "red" : "gray", fontWeight: 700 } : { color: styles2.includes('twoStar') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Threestar':
                                                return data.name === 'threeStar' ? data.ratingFields.map((item: any) => <p style={styles3.includes('threeStar') ? { color: styles3.includes('threeStar') ? "red" : "gray", fontWeight: 700 } : { color: styles3.includes('threeStar') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fourstar':
                                                return data.name === 'fourStar' ? data.ratingFields.map((item: any) => <p style={styles4.includes('fourStar') ? { color: styles4.includes('fourStar') ? "red" : "gray", fontWeight: 700 } : { color: styles4.includes('fourStar') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fivestar':
                                                return data.name === 'fiveStar' ? data.ratingFields.map((item: any) => <p style={styles5.includes('fiveStar') ? { color: styles5.includes('fiveStar') ? "red" : "gray", fontWeight: 700 } : { color: styles5.includes('fiveStar') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            default:
                                                return '-';
                                        }
                                    })}

                                </td>
                                <td>
                                    {reviewEdits.newData.ratings.map((data: any) => {
                                        switch (value) {
                                            case 'Onestar':
                                                return data.name === 'oneStar' ? data.ratingFields.map((item: any) => <p style={styles1.includes('oneStar') ? { color: styles1.includes('oneStar') ? "green" : "gray", fontWeight: 700 } : { color: styles1.includes('oneStar') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Twostar':
                                                return data.name === 'twoStar' ? data.ratingFields.map((item: any) => <p style={styles2.includes('twoStar') ? { color: styles2.includes('twoStar') ? "green" : "gray", fontWeight: 700 } : { color: styles2.includes('twoStar') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Threestar':
                                                return data.name === 'threeStar' ? data.ratingFields.map((item: any) => <p style={styles3.includes('threeStar') ? { color: styles3.includes('threeStar') ? "green" : "gray", fontWeight: 700 } : { color: styles3.includes('threeStar') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fourstar':
                                                return data.name === 'fourStar' ? data.ratingFields.map((item: any) => <p style={styles4.includes('fourStar') ? { color: styles4.includes('fourStar') ? "green" : "gray", fontWeight: 700 } : { color: styles4.includes('fourStar') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fivestar':
                                                return data.name === 'fiveStar' ? data.ratingFields.map((item: any) => <p style={styles5.includes('fiveStar') ? { color: styles5.includes('fiveStar') ? "green" : "gray", fontWeight: 700 } : { color: styles5.includes('fiveStar') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            default:
                                                return '-';
                                        }
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TemplateHistoryView