import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/ApiResponse.interface'

export class RatingTemplateService extends BaseService {
  public async addRatingTemplate(userInformation: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ADD_TEMPLATE,
        { ...userInformation },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateRatingTemplate(ratingFieldInformation: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.put(
        API_ENDPOINTS.UPDATE_TEMPLATE + ratingFieldInformation._id,
        ratingFieldInformation,
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getRatingTemplates(
    template?: any,
    status?: string,
    sortName?: string,
    sort?: string,
    page?: number,
    limit?: number
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.GET_AlL_TEMPLATE}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&status=${status}&template=${template ? template : ''}&sortName=${sortName ? sortName : ''}&sort=${
          sort ? sort : '-1'
        }`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getOneRatingTemplate(id: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_TEMPLATE + id, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async deleteRatingTemplate(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.delete('inventory/deleteReview', {
        data: { erp_prod_id: code }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
