import React from 'react'
import './styles.scss'
import AppheaderRightContent from 'components/AppheaderRightContent'


type headerLayoutProps = {
    title: string
    status?: string
    to?: string
}


const AppHeaderLayout: React.FC<headerLayoutProps> = ({ title, status }) => {
  

    return (
        <div className='app-headers'>

            <div className="title-status-wrapper">
                <p className="header-title">{title}</p>
                <p className="header-status">{status}</p>

            </div>
                <div className="header-logout-wrapper">
                    <AppheaderRightContent />
                </div>
        </div>
    )

}

export default AppHeaderLayout

