import { TextField } from '@mui/material'
import React from 'react'

import './styles.scss'

type Tprops = {
  text: string
  error?: boolean
  errorText?: any
  register?: any
  type?: string
  maxLength?: any
} & React.InputHTMLAttributes<HTMLInputElement>

const InputFieldWithLabels: React.FC<Tprops> = ({ register, type, text, error, errorText, maxLength, ...props }) => {
  return (
    <div className="input-label-wrapper">
      <div className="input-label">
        <label className='temp-la'>{text}</label>
        <div className="input-wrap">
          <TextField
            // maxLength={maxLength}
            variant="outlined"
            type={type}
            autoComplete="off"
            {...register}
            className={error ? 'input-field-box-error' : 'input-field-box'}
            placeholder={text}
            {...props}
            inputProps={{
              maxLength: maxLength,
            }}
          />
          {error ? <p className="error-text-wrap">* {errorText}</p> : ''}
        </div>
      </div>
    </div>
  )
}

export default InputFieldWithLabels
