import { Pagination } from '@mui/material'
import ActionButton from 'components/ActionButton'
import UserDetails from 'containers/forms/UserDetails'
import moment from 'moment'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
// import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { IUserData } from 'services/interfaces/user.interface'
import { UserService } from 'services/user.service'
import appContext from '../../context/app.context'
// import { IconProp } from '@fortawesome/fontawesome-svg-core'
// import Swal from 'sweetalert2'
import './styles.scss'
// import { showNotification, STATUS } from 'common/constant'
export const UserPage: React.FC = () => {
  const [showUser, setshowUser] = useState<boolean>(false)
  const [userList, setUserList] = useState<IUserData[] | []>([])
  const [isEditData, setIsEditData] = useState<any>()
  const { setSpinnerVisibility } = useContext(appContext)
  const [pageCount, setPageCount] = useState<number>()
  const [page, setPage] = React.useState(1)
  // const [username, setUsername] = useState<string>('')
  const navigate = useNavigate()

  const userService = new UserService()

  const getuserList = async () => {
    try {
      const data = await userService.getUser()

      const users = data?.data.map((user: any) => {
        user.createdAt = moment(user.createdAt).format('DD-MM-YYYY hh:mm:ss A')

        return user
      })
      setPageCount(Math.ceil(Number(data.count) / 10))
      setUserList(users || [])
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setSpinnerVisibility(true)
    multipleProductFilter("",value)
    // setSpinnerVisibility(false)
  }

  const multipleProductFilter = async (user?: any, page?: any) => {
    try {
      setSpinnerVisibility(true)
      // let usersName = user ? user : username
      const data = await userService.getUser(user,page)

      const users = data?.data.map((user: any) => {
        user.createdAt = moment(user.createdAt).format('YYYY-MM-DD hh:mm:ss A')

        return user
      })
      setPageCount(Math.ceil(Number(data.count) / 10))
      setUserList(users || [])
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }

  // const deleteUser = async (erp_prod_id: string) => {
  //   try {
  //     Swal.fire({
  //       title: 'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Yes, delete it!'
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
  //       }
  //     })
  //     const deleteUserResponse = await userService.deletewarehouse(erp_prod_id)
  //     console.log(deleteUserResponse)
  //     getuserList()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleProductFilter = () => {
  //   if (username) {
  //     setSpinnerVisibility(true)
  //   multipleProductFilter(username, page)
  //   } else {
  //     showNotification(STATUS.FAILURE, 'Please Enter Value')
  //   }
  // }

  const getSerialNumber = (index: number) => {
    if (page === 1 && index < 9) return index + 1
    else if (index === 9) return page + '0'

    return `${page - 1}${index + 1}`
  }
  useEffect(() => {
    setSpinnerVisibility(true)
    getuserList()
  }, [])

  return (
    <div className="user-list-page">
      <div className="table-wrapper">
        <div className="btn-wrapper">
          <ActionButton type="button" label="+ Add User" onClick={() => navigate('new')} />
        </div>

        {/* <div className="filter-head ">
          <div className="filter-item">
            <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
          </div>

          <div className="filter-item">
            <FormControl fullWidth>
              <TextField
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                size="small"
                id="outlined-basic"
                label="User Name"
                variant="outlined"
              />
            </FormControl>
          </div> */}
          {/* <div className="filter-item">
              <FormControl fullWidth>
                <TextField onChange={(e) => setBrand(e.target.value)} value={brand} size='small' id="outlined-basic" label="Brand Name" variant="outlined" />
              </FormControl>
            </div> */}

          {/* <div className="filter-item filter-action">
            <ActionButton
              label="Apply"
              onClick={() => {
                handleProductFilter()
              }}
            />
            <ActionButton
              label="Clear"
              onClick={() => {
                setUsername('')
                getuserList()
              }}
            />
          </div>
        </div> */}
        <table className="user-list-table">
          <thead>
            <th>S.No</th>
            <th>Username</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Role</th>
            <th>Created Date</th>
            <th>Actions</th>
          </thead>
          {userList.length ? (
            <tbody>
              {/* {userList.map((userData: IUserData) => { */}
              {userList.map((userData: any, index: number) => {
                return (
                  <tr key={userData._id} className="user-list-tbody">
                    <td>{getSerialNumber(index)}</td>
                    <td>{userData.userName}</td>
                    <td>{userData.mobileNumber}</td>
                    <td>{userData.email}</td>
                    <td>{userData.role}</td>
                    <td>{userData.createdAt}</td>
                    <td>
                      <div className="action-btn-wrap">
                        <ActionButton
                          label="View"
                          onClick={() => {
                            setIsEditData(userData)
                            setshowUser(true)
                          }}
                        />
                        {/* <ActionButton label="Edit" onClick={() => navigate('edit/:' + userData._id)} /> */}
                        {/* <ActionButton label="Delete" onClick={() => deleteUser(userData._id)} /> */}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="empty-data" colSpan={12}>
                  No data to display
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className="all-products-pagination">
          <Pagination
            className="all-products-pagination"
            count={pageCount}
            // defaultPage={page}
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>

      {showUser ? (
        <UserDetails
          userData={isEditData}
          show={showUser}
          handleClose={() => {
            setshowUser(!showUser)
            getuserList()
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
