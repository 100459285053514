import ThemeContext from 'context/ThemeContext';
import DashboardLayout from 'layouts/index';
import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import appContext from "./context/app.context";
import './App.css';
import { ToastContainer } from 'react-toastify';

import { LoginPage } from './pages/loginPage';
import keycloakConfig from './keycloak'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from 'components/Spinner'
import { EToasterVariants, ToasterProperties } from 'services/types/comman.type'
import { toasterDuration } from 'common/constant'
import Toaster from 'components/Toaster'
var timeout: any;

function App(): React.ReactElement {
  const themes = useContext(ThemeContext)
  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);
  const [spinnerVisibility, setSpinnerVisibility] = useState<boolean>(false);

  const [toasterProps, setToasterProps] = useState<ToasterProperties>({
    type: EToasterVariants.success,
    message: "Success Message",
    visibility: false,
  });
  const [backDrop, setbackDrop] = useState<boolean>(false);

  const showToaster = (type: EToasterVariants, message: string) => {
    clearTimeout(timeout);
    setToasterProps({ type, message, visibility: true });
    timeout = setTimeout(() => {
      setToasterProps({ type, message, visibility: false });
    }, toasterDuration);
  };

  const contextItems = {
    sideMenuVisibility,
    setSideMenuVisibility,
    toasterProps,
    showToaster,
    spinnerVisibility,
    setSpinnerVisibility,
    backDrop,
    setbackDrop,
  };

  const eventLogger = (event: unknown, error: unknown) => {
    console.log('onKeycloakEvent', event, error)
  }

  const tokenLogger = (tokens: unknown) => {
    console.log('onKeycloakTokens', tokens)
  }

  return (
    <ReactKeycloakProvider authClient={keycloakConfig} onEvent={eventLogger} onTokens={tokenLogger}>
      <appContext.Provider value={contextItems}>
      
      <ThemeContext.Provider value={themes}>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboardLayout/*" element={<DashboardLayout />} />
          </Routes>
          <Spinner />
<Toaster />
        </Router>
      </ThemeContext.Provider>
      </appContext.Provider>

    </ReactKeycloakProvider>
  )
}

export default App
