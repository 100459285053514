import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { FormControl } from '@mui/material'
import { Controller } from 'react-hook-form'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

type TInputFieldProps = {
  field: any
  defaultValue : []
  text?: string
  error: boolean
  errorText?: any
  register?: any
  control: any
  name?: string
  hint?: string
  dropdown: string[] | []
  notrequired?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const MaterialAutoComplete: React.FC<TInputFieldProps> = ({
  register,
  name,
  text,
  control,
  hint,
  error,
  errorText,
  dropdown,
  field,
  notrequired,
  defaultValue,
  disabled,
  ...props
}) => {
  
  return (
    <div className="input-with-dropdown">
      <div className="label-filed-wrap ">
        <label className="txt-label mdrop autodrop">{text} </label>

        <Controller
          control={control}
          name={text}
          defaultValue={defaultValue}
          {...register}
          {...props}
          render={({ field: { onChange, value } }) => (
            <FormControl size="small" fullWidth>
              <Autocomplete
              disabled={disabled}
                multiple
                onChange={(event, item) => {
                  onChange(item)
                }}
                defaultChecked
                value={value}
                options={dropdown}
                // readOnly = {defaultValue.length > 0}
                disableCloseOnSelect
                getOptionLabel={(item) => (item[field] ? item[field] : '')}
                
                //style={{ width: 500 }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option[field]}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    
                    {...params}
                    label={text}
                    margin="normal"
                    variant="outlined"
                    error={!!error}
                    helperText={error ? errorText : ''}
                    size="small"
                    fullWidth 
                  />
                )}
              />
            </FormControl>
          )}
        />

     

        {/* {error ? <p className="error-text-wrap">* {errorText}</p> : ''} */}
      </div>
    </div>
  )
}

export default MaterialAutoComplete
