import { AxiosError } from 'axios'
import { intersection, isEmpty } from 'lodash'
import { IErrorResponse } from 'services/interfaces/ApiResponse.interface'
import { toast } from 'react-toastify'

export const toasterDuration = 2000;

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_URL;
export const PIM_API_BASE_URL = process.env.REACT_APP_PIM_API_BASE_URL;
export const CRM_API_BASE_URL = process.env.REACT_APP_API_CRM_BASE_URL;

export const API_ENDPOINTS = {
  //Login or Register
  LOGIN: '/user/login',
  SSO_LOGIN: '/user/ssologin',
  ADD_USER_DETAILS: '/user/register',
  GET_All_USERS: '/user',
  GET_USER_DETAILS: '/users/login',
  UPDATE_USER_DETAILS: '/users/login',
  FORGOT_PASSWORD: '/users/login',
  RESEND_ACTIVATION_LINK: '/users/login',
  RESET_PASSWORD: '/users/login',
  ACTIVATE_EMAIL: '/users/login',
  GET_NOTIFICATION: '/',
  READ_NOTIFICATION_STATUS: '/',

  //Review
  ADD_REVIEW: '/admin/review',
  GET_All_REVIEW: '/admin/review',
  GET_REVIEW: '/admin/review/',
  UPDATE_REVIEW: '/admin/review/',
  GET_CUSTOMER_LIST: '/external',
  REVIEW_HISTORY:'/admin/history',

  //Review
  ADD_RATINGFIELD: '/admin/rating-field',
  GET_All_RATINGFIELD: '/admin/rating-field',
  GET_RATINGFIELD: '/admin/rating-field/',
  UPDATE_RATINGFIELD: '/admin/rating-field/',

  //template
  ADD_TEMPLATE: '/admin/rating-field-template',
  GET_AlL_TEMPLATE: '/admin/rating-field-template',
  GET_TEMPLATE: '/admin/rating-field-template/',
  UPDATE_TEMPLATE: '/admin/rating-field-template/',

  //Assign Product
  ADD_PRODUCT: '/rating-field-template',
  GET_AlL_PRODUCT: '/admin/product',
  GET_PRODUCT_VARIANT: '/admin/variant-product-filter/',
  ASSIGN_RATING_FIELDS: '/admin/product-review-field',
  UPDATE_PRODUCT_FIELD :'/admin/product-review-field/',
  ASSIGN_PRODUCT_HISTORY:'/admin/history',

  //Assign Product New API (PIM)
  PRODUCT_LIST: '/review/product/model/search',
  GET_PRODUCT_VARIANT_PIM: '/review/product/getVariants',
  PRODUCT_MODEL:'/review/product/model',


  //Image Upload
  BASE_IMG_URL: 'https://img.poorvika.com/cdn-cgi/image/width=450,height=450,quality=50/',
  MULTIPLE_IMAGE_UPLOAD: 'https://breactweb.poorvika.com/api/v1/imageupload/multipleImage'
}

export const STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const ROLES = {
  ROOT_ADMIN: 'ROOT_ADMIN',
  USERS: 'USERS',
  GUESTS: 'GUESTS'
}

export const dashboardPath = ['/dashboard']

export const profileDetailsSubPaths = [
  '/profileDetials/Overview',
  '/profileDetials/LegalDetails',
  '/profileDetials/Tax&Financials',
  '/profileDetials/KYCDocuments',
  '/profileDetials/Contacts',
  '/profileDetials/BankAccount',
  '/profileDetials/information',
  /* Admin Seller Url */
  '/admin/seller/profileDetials/Overview',
  '/admin/seller/profileDetials/LegalDetails',
  '/admin/seller/profileDetials/BankAccount',
  '/admin/seller/profileDetials/Tax&Financials',
  '/admin/seller/profileDetials/KYCDocuments',
  '/admin/seller/profileDetials/Contacts',
  '/admin/seller/profileDetials/information'
]

export const settingsSubpaths = [
  '/admin/settings',
  '/admin/settings/registration',
  '/admin/settings/onboarding',
  '/admin/settings/history'
]

export const parseAxiosError = (error: AxiosError): IErrorResponse => {
  if (error.isAxiosError && error.response) {
    return { status: 'success', message: error.response.data.message, error }
  }

  return { status: 'failure', message: error.message, error }
}

export const showNotification = (type: string, message: string): void => {
  if (type === STATUS.SUCCESS) {
    toast.success(message, { theme: 'colored' })
  } else if (type === STATUS.FAILURE) {
    toast.warn(message, { theme: 'colored' })
  }
}

export const isVisible = (
  roles: Array<string>,
  allowedRoles: { admin?: Array<string>; user?: Array<string> }
): boolean => {
  if (!isEmpty(roles) && !isEmpty(allowedRoles)) {
    const adminRoles = allowedRoles?.admin ? allowedRoles.admin : []
    const userRoles = allowedRoles.user

    if (!isEmpty(adminRoles) && intersection(roles, adminRoles).length > 0) {
      return true
    } else if (!isEmpty(userRoles) && intersection(roles, userRoles).length > 0) {
      return true
    }

    return false
  }

  return false
}

export enum LIST_OF_ROLES {
  /*Inventory and Warehouse*/
  'WAREHOUSE_CREATE' = 'WAREHOUSE_CREATE',
  'WAREHOUSE_EDIT' = 'WAREHOUSE_EDIT',
  'WAREHOUSE_DELETE' = 'WAREHOUSE_DELETE',
  'WAREHOUSE_LIST' = 'WAREHOUSE_LIST',
  'WAREHOUSE_VIEW_STOCK' = 'WAREHOUSE_VIEW_STOCK',
  'WAREHOUSE_UPDATE_STOCK' = 'WAREHOUSE_UPDATE_STOCK',
  'WAREHOUSE_ADD_PRODUCT' = 'WAREHOUSE_ADD_PRODUCT',
  'EXPORT_INVENTORY' = 'EXPORT_INVENTORY',
  'BULK_UPLOAD_STOCK' = 'BULK_UPLOAD_STOCK',
  /*Product Listing*/
  'PRODUCT_VIEW' = 'PRODUCT_VIEW',
  'PRODUCT_ADD' = 'PRODUCT_ADD',
  'PRODUCT_EDIT' = 'PRODUCT_EDIT',
  'PRODUCT_DELIST' = 'PRODUCT_DELIST',
  'PRODUCT_APPROVE_REJECT' = 'PRODUCT_APPROVE_REJECT',
  'PRODUCT_BULK_UPLOAD' = 'PRODUCT_BULK_UPLOAD',
  /*Seller Information */
  'LEAGAL_CREATE' = 'LEAGAL_CREATE',
  'LEAGAL_UPDATE' = 'LEAGAL_UPDATE',
  'LEAGAL_VIEW' = 'LEAGAL_VIEW',
  'BANK_CREATE' = 'BANK_CREATE',
  'BANK_UPDATE' = 'BANK_UPDATE',
  'BANK_VIEW' = 'BANK_VIEW',
  'CONTACT_CREATE' = 'CONTACT_CREATE',
  'CONTACT_UPDATE' = 'CONTACT_UPDATE',
  'CONTACT_VIEW' = 'CONTACT_VIEW',
  'TAX_FINANCE_CREATE' = 'TAX_FINANCE_CREATE',
  'TAX_FINANCE_UPDATE' = 'TAX_FINANCE_UPDATE',
  'TAX_FINANCE_VIEW' = 'TAX_FINANCE_VIEW',
  'KYC_CREATE' = 'KYC_CREATE',
  'KYC_UPDATE' = 'KYC_UPDATE',
  'KYC_VIEW' = 'KYC_VIEW',
  'SELLER_SUMMARY' = 'SELLER_SUMMARY',
  'PURCHASE_ORDER_VIEW' = 'PURCHASE_ORDER_VIEW',
  'PURCHASE_ORDER_SEARCH' = 'PURCHASE_ORDER_SEARCH',
  'PURCHASE_ORDER_UPDATE' = 'PURCHASE_ORDER_UPDATE',
  'DC_INWARD' = 'DC_INWARD',
  'EXPORT_ORDERS' = 'EXPORT_ORDERS'
}

export const rolesPersmission = [
  'Seller_Information',
  'Product_Listing',
  'Inventory',
  'Purchase_Order',
  'Finance',
  'Sellers'
]
