import { Dialog, DialogContent, Pagination } from '@mui/material'
import ActionButton from 'components/ActionButton'
import React, { useContext, useEffect, useState } from 'react'
import { ISuccessResponse } from 'services/interfaces/ApiResponse.interface'
import CloseIcon from '@mui/icons-material/Close'
import appContext from 'context/app.context'
import { showNotification, STATUS } from 'common/constant'
import { AssignProductService } from 'services/assign.service'
import AssignedProductHistoryView from './AssignedProductHistoryView'
import moment from 'moment'


const AssignedProductHistory
    = () => {
        const [showHistory, setshowRatingHistory] = useState([])
        const assignProductService = new AssignProductService();
        const [pageNo, setPageNo] = useState<number>(1);
        const [pageHistoryCount, setPageHistoryCount] = useState<number>(1);
        const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0)
        const [open, setOpen] = React.useState(false)
        const [showHistoryview, setShowHistoryview] = useState()
        const { setSpinnerVisibility } = useContext(appContext);

        const getAssinginghistory = async () => {
            try {
                setSpinnerVisibility(true);
                const reviewResponse: ISuccessResponse = (await assignProductService.getAssingProductHistory()) as ISuccessResponse
                if (reviewResponse.statusCode === 200) {
                    setPageHistoryCount(Math.ceil(Number(reviewResponse.count) / 10))
                    setTotalHistoryCount(reviewResponse.count || 0)
                    setshowRatingHistory(reviewResponse.data)
                    setSpinnerVisibility(false);
                } else {
                    setSpinnerVisibility(false);
                    showNotification(STATUS.FAILURE, "Too Many Requests")
                }
            } catch (error) {
                console.log(error)
            }
        }

        const getSerialNumber = (index: number) => {
            if (pageNo === 1 && index < 9)
                return index + 1
            else if (index === 9)
                return pageNo + "0"
            return `${pageNo - 1} ${index + 1}`
        }

        const getPageHistoryResult = () => {
            if (totalHistoryCount > 10 && pageNo * 10 < totalHistoryCount)
                return pageNo * 10
            return totalHistoryCount
        }
        const handleClose = () => {
            setOpen(false)
        }
        useEffect(() => {
            getAssinginghistory()
        }, [pageNo])

        const getAssignHistoryViewList = React.useCallback(async (id: any) => {
            try {
                const showReviewHistory: ISuccessResponse = (await assignProductService.getAssingProductHistoryView(id)) as ISuccessResponse

                if (showReviewHistory.statusCode === 200) {
                    setShowHistoryview(showReviewHistory.data)
                    setOpen(true)

                } else {
                    console.log('firstss', showReviewHistory)
                }
            } catch (error) {
                console.log(error)
            }
        }, [showHistoryview])

        return (
            <div className="listed-product-layout">
                <div className="Inventory-table-layout">
                    <div className="Inventory-table-header">
                        <div className="listed-products-selector">
                            <p>Assigning Product Table History</p>
                        </div>
                    </div>
                    <div className="Inventory-table-body">
                        <table className="inventory-products-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Name</th>
                                    <th>Action</th>
                                    <th>Modified Date</th>
                                </tr>
                            </thead>
                            {showHistory.length ?
                                (<tbody>
                                    {showHistory.map((ele: any, index: number) => {
                                        return (
                                            <tr key={ele._id} className="user-list-tbody">
                                                <td>
                                                    {getSerialNumber(index)}
                                                </td>
                                                <td>
                                                    {ele.name}
                                                </td>

                                                <td>
                                                    {ele.method}
                                                </td>
                                                <td>
                                                    {moment(ele.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}

                                                </td>
                                                <td>
                                                    <div className="product-col">
                                                        <ActionButton
                                                            label="View"
                                                            onClick={() => { getAssignHistoryViewList(ele._id) }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>)
                                : (
                                    <tbody>
                                        <tr>
                                            <td className="empty-data" colSpan={12}>
                                                No data to display
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            }

                        </table>
                        <div className="all-products-count">
                            {' Showing Result '}
                            {getPageHistoryResult()} of {totalHistoryCount}
                        </div>
                        <div className="all-products-pagination">
                            <Pagination
                                className="all-products-pagination"
                                shape='rounded'
                                variant='outlined'
                                page={pageNo}
                                count={pageHistoryCount}
                                onChange={(event: any, value: number) => {
                                    setPageNo(value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                    <div className="dilog-title">
                        <CloseIcon onClick={handleClose} className="pointer-events" />
                    </div>
                    <DialogContent >
                        <AssignedProductHistoryView reviewEdits={showHistoryview} />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

export default AssignedProductHistory
