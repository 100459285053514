import React, { useContext, useEffect, useState } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'
import InputFieldWithLabels from 'components/InputFieldWithLabels'
import ActionButton from 'components/ActionButton'
import { ReviewService } from 'services/review.service'
// import { IReviewData } from "services/interfaces/review.interface";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// import { UserService } from 'services/user.service'
// import { useNavigate } from 'react-router-dom'
// import { showNotification, STATUS } from 'common/constant'
import Alert from '@mui/material/Alert';
import RatingStars from 'components/RatingStar'
import MaterialDropDown from 'components/MaterialDropDown'
import { TextareaAutosize } from '@mui/material'
import { showNotification, STATUS } from 'common/constant'
import appContext from '../../../context/app.context'
// import { FaSpinner } from 'react-icons/fa'
// import { getValue } from '@testing-library/user-event/dist/utils'

// import MaterialDropDown from 'components/MaterialDropDown'

// interface formFields extends IReviewData {

//     row_added_by: string,
//     row_added_date: string,
//     row_updated_by: string,
//     row_updated_date: string
// }

// type Tprops = {
//     reviewData: formFields
// }

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  rating: Yup.string().required('Rating is required'),
  recommended: Yup.string().required('Recommended  is required'),
  publish: Yup.string().required('Publish is required')
})
const formOptions = { resolver: yupResolver(validationSchema) }

const enable_list = ['Yes', 'No']
const reviewStatus = ['Approve For Later', 'Invalid', 'Valid']
const recommended_list = ['Yes', 'No', 'Not Recommended']
type Tprops = {
  reviewEdits?: any
  onClick?: () => void
  handleCloseUpdated?: () => void
  // _id?: any
  // publish: any
  // customer: any
  // recommended: any
  // ratings: []
  // rating: any
  // title: any
  // text: any
  // itemCode: any
  // productId: any
  // likes: []
  // dislikes: []
  // disLikeCount: any
  // likeCount: any
  // images: []
}

const ReviewAddAndEdit: React.FC<Tprops> = ({ reviewEdits, onClick, handleCloseUpdated }) => {
  // const imgRef = useRef<any>(null)
  // const [reviewData, setReviewData] = useState<any>({})
  const { setSpinnerVisibility } = useContext(appContext)
  const [isLoading, setIsLoading] = useState(false);
  const [buttondisabled, setButtondisabled] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = useForm(formOptions);

  const reviewService = new ReviewService()
  const t1 = {
    title: reviewEdits.title,
    text: reviewEdits.text,
    recommended: reviewEdits.recommended,
    publish: reviewEdits.publish,
    edit_status: reviewEdits.edit_status
  }
  // let test = {};
  const setValuesAndValidate = (field: string, value: any) => {
    setValue(field, value);
    const updatedTest = {
      title: getValues().title,
      text: getValues().text,
      recommended:
        getValues().recommended === 'Yes' ? 1 : getValues().recommended === 'No' ? -1 : getValues().recommended === 'Not Recommended' ? 0 : '',
      publish: getValues().publish === 'No' ? false : true,
      edit_status: getValues().edit_status === 'IV' ? 'Invalid' : getValues().edit_status === 'AFL' ? 'Approve For Later' : 'Valid'
    };

    const objReview = JSON.stringify(t1);
    const newObjReview = JSON.stringify(updatedTest);
    setButtondisabled(objReview !== newObjReview);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValuesAndValidate('title', e.target.value);
  };

  const onhandleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValuesAndValidate('text', e.target.value);
  };

  const onhandleRecommChange = (value: any) => {
    setValuesAndValidate('recommended', value);
  };
  const [showAlert, setShowAlert] = useState(false);

  const onhandlePublishChange = (value: any) => {
    setValuesAndValidate('publish', value);
    if ((getValues().edit_status === 'Invalid' || getValues().edit_status === 'Approve For Later') && value === 'Yes') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };
  const onhandleReviewStatusChange = (value: any) => {
    setValuesAndValidate('edit_status', value);
    if ((value === 'Invalid' || value === 'Approve For Later') && getValues().publish === 'Yes') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };
  
  useEffect(() => {
    if (reviewEdits) {
      setValue('productName', reviewEdits?.productName)
      setValue('customerName', reviewEdits?.customerName)
      setValue('itemCode', reviewEdits?.itemCode)
      setValue('title', reviewEdits?.title)
      setValue('text', reviewEdits?.text)
      setValue('rating', reviewEdits?.rating)
      setValue(
        'recommended',
        reviewEdits?.recommended === 1
          ? 'Yes'
          : reviewEdits?.recommended === -1
            ? 'No'
            : reviewEdits?.recommended === 0
              ? 'Not Recommended'
              : '',
        { shouldValidate: true }
      )
      setValue('publish', reviewEdits?.publish == true ? 'Yes' : 'No')
      setValue('edit_status', reviewEdits?.edit_status === 'IV' ? 'Invalid' : reviewEdits?.edit_status === 'AFL' ? 'Approve For Later' : reviewEdits?.edit_status === '' ? 'Valid' : 'Valid')
    }

  }, [reviewEdits])

  const onSubmit = async (data: any) => {
    setSpinnerVisibility(true)
    setIsLoading(true);
    try {
      data.productId = Number(data.productId)
      let recommendedValue = 0

      if (data.recommended === 'Yes') {
        recommendedValue = 1
      } else if (data.recommended === 'No') {
        recommendedValue = -1
      } else if (data.recommended === 'Not Recommended') {
        recommendedValue = 0
      }
      data.recommended = recommendedValue
      data.publish = data.publish === 'Yes' ? true : false

      if (data.edit_status === 'Invalid') {
        data.edit_status = 'IV'
      } else if (data.edit_status === 'Approve For Later') {
        data.edit_status = 'AFL'
      } else {
        data.edit_status = ''
      }

      if (reviewEdits._id) {
        // setSpinnerVisibility(true)
        const updateInventoryResponse = await reviewService.updateReview({ ...reviewEdits, ...data })
        if (updateInventoryResponse.statusCode === 200) {
          // setSpinnerVisibility(true)
          setIsLoading(false)
          handleCloseUpdated?.()
          showNotification(STATUS.SUCCESS, 'Updated Successfully')
        }
      } else {
        const addInventoryResponse = await reviewService.addReview(data)
        if (addInventoryResponse.statusCode === 200) showNotification(STATUS.SUCCESS, 'Add Successfully')
      }
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="inventory-add-form">
      <p className="title-content">{reviewEdits._id ? 'EDIT REVIEW' : 'ADD REVIEW'}</p>
      <div className="inventory-form">
        <form className="form-review" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '30px' }}>
          <InputFieldWithLabels
            register={register('productName')}
            error={errors.productName?.type !== undefined}
            errorText={errors.productName?.message}
            text="Product"
            // defaultValue={reviewEdits?.productName}
            disabled
          />
          <InputFieldWithLabels
            register={register('customerName')}
            error={errors.customerName?.type !== undefined}
            errorText={errors.customerName?.message}
            text="Customer"
            // defaultValue={reviewEdits?.customerName}
            disabled
          />
          <InputFieldWithLabels
            register={register('itemCode')}
            error={errors.itemCode?.type !== undefined}
            errorText={errors.itemCode?.message}
            text="Item Code"
            // defaultValue={reviewEdits?.itemCode}
            disabled
          />
          <InputFieldWithLabels
            // register={register('title')}
            onChange={handleInputChange}
            error={errors.title?.type !== undefined}
            maxLength={20}
            errorText={errors.title?.message}
            text="Title"
            defaultValue={reviewEdits?.title}
          />
          <div className="input-label-wrapper">
            <div className="input-label">
              <label>Review</label>
              <div className="input-wrap">

                {/* {
                  reviewEdits.text ==""?
                  <span>
                    {console.log('first', reviewEdits.text)}
                    {errors[0]?.msg}</span>: */}
                <TextareaAutosize
                  cols={50}
                  maxLength={400}
                  style={{ fontSize: '1rem', width: '316px' }}
                  autoComplete="off"
                  onChange={onhandleTextChange}

                  // {...register('text')}
                  className="input-field-box-error"
                  placeholder="Enter the Review"
                  defaultValue={reviewEdits?.text}
                />
                {/* } */}

              </div>
            </div>
          </div>
          <div className="input-label-wrapper">
            <div className="input-label">
              <label>Images </label>
              <div className='rev-img'>
                {
                  reviewEdits.images?.map((item: any) => (

                    <div key={item.image} className="form-data">
                      <span className="form-data-det">

                        <a href={item.image} target="_blank" rel="noopener noreferrer">
                          <img src={item.image} alt="Review-Img" />
                        </a>

                      </span>
                    </div>

                  ))
                }
              </div>
            </div>
          </div>

          <div className="input-with-dropdown">
            <div className="label-filed-wrap label-drop">
              <label className="txt-label">Rating </label>

              <RatingStars stars={reviewEdits.rating} />
            </div>
          </div>


          <MaterialDropDown

            dropdown={recommended_list}
            register={register('recommended')}
            error={errors.recommended?.type !== undefined}
            errorText={errors.recommended ? errors.recommended.message : ''}
            text="Recommended"

            defaultValue={
              reviewEdits?.recommended === 1
                ? 'Yes'
                : reviewEdits?.recommended === -1
                  ? 'No'
                  : reviewEdits?.recommended === 0
                    ? 'Not Recommended'
                    : ''
            }
            control={control}
            field="recommended"
            onChanges={onhandleRecommChange}

          />


          <MaterialDropDown
            dropdown={enable_list}
            register={register('publish')}
            error={errors.publish?.type !== undefined}
            errorText={errors.publish ? errors.publish.message : ''}
            text="Publish"
            defaultValue={reviewEdits.publish ? 'Yes' : 'No'}
            control={control}
            field="publish"

            onChanges={onhandlePublishChange}
          />
          <MaterialDropDown
            dropdown={reviewStatus}
            register={register('edit_status')}
            error={errors.edit_status?.type !== undefined}
            errorText={errors.edit_status ? errors.edit_status.message : ''}
            text="Review Status"
            defaultValue={reviewEdits.edit_status === 'IV' ? 'Invalid' : reviewEdits.edit_status === 'AFL' ? 'Approve For Later' : 'Valid'}
            control={control}
            field="edit_status"
            onChanges={onhandleReviewStatusChange}
          />
          {showAlert && (
            <div >
              <Alert severity="warning" className="alertmsg">Please change the 'Publish' status Yes to No.</Alert>
            </div>
          )}

          <div className="form-btn-wrap">
            <ActionButton color="outlined" label="Cancel" type="button" onClick={onClick} />
            <ActionButton style={!buttondisabled || isLoading || showAlert ? { background: "#ff8e3380", pointerEvents: 'none' } : { background: "#ff8e33" }} label={(reviewEdits._id ? 'UPDATE REVIEW' : 'ADD REVIEW')} type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReviewAddAndEdit
