import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const AuthRoute: React.FC = () => {
  const token = localStorage.getItem('accessToken')
  const location = useLocation();


  return token ? <Outlet/> : <Navigate to="/login" state={{ from: location }} />
}

export default AuthRoute
