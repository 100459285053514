
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

type Tprops = {
    reviewEdits?: any
    onClick?: () => void
    handleCloseUpdated?: () => void
}
const AssignedProductHistoryView: React.FC<Tprops> = ({ reviewEdits }) => {
    const newdata = reviewEdits.newData;
    const olddata = reviewEdits.oldData;
    function compareJSONObjects(obj1: any, obj2: any): any[] {
        const arr: any[] = [];
        for (const prop in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
                const myobj: any = {};
                const value1 = obj1[prop];
                const value2 = obj2[prop];
                if (Array.isArray(value1) && Array.isArray(value2)) {
                    ArrayComparison(value1, value2);
                } else if (typeof value1 === "object" && typeof value2 === "object") {
                    compareJSONObjects(value1, value2);
                } else if (value1 !== value2) {
                    myobj['Properties'] = prop;
                    myobj["current-" + prop] = value1;
                    myobj["previous-" + prop] = value2;
                    arr.push(myobj);
                }
            }
        }
        return arr;
    }

    function ArrayComparison(array1: any[], array2: any[]): void {
        array1.forEach((value, index) => {
            if (typeof value !== "string") {
                compareJSONObjects(value, array2[index]);
            }
        })}
    const result = compareJSONObjects(newdata, olddata);
    var styles: any = result.map((item: any) => item.Properties);

    const [value, setValue] = useState('');

    return (
        <div className="listed-product-layoutHistory">
            <div className="Inventory-table-layoutHistory">
                <div className="Inventory-table-headerHistory">
                    <div className="listed-products-selectorHistory">
                        <p>Assigning Product History</p>
                    </div>
                </div>
                <div className="Inventory-table-bodyHistory">
                    <table className="inventory-products-tableHistory">
                        <thead>
                            <tr>
                                <th style={{display:'flex', justifyContent:'start'}}>Fields Name</th>
                                <th>Previous Data</th>
                                <th>Current Data</th>
                            </tr>
                        </thead>
                        <tbody>

                       
                            {/* <tr className="user-list-tbodyHistory">
                                <td>
                                    <p className='textfont'>Template Name</p>
                                </td>
                                <td>
                                    {reviewEdits.oldData.templateName}
                                </td>
                                <td>
                                    {reviewEdits.newData.templateName}
                                </td>
                            </tr> */}
                            <tr className="user-list-tbodyHistory">
                                <td className='textfont' style={{ display: 'flex' }}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label" style={{ textAlign: 'start', fontSize: '15px', fontWeight: 700, margin: '0px' }}>Ratings</FormLabel>
                                        <RadioGroup
                                            // aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Onestar"
                                            name="radio-buttons-group"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            <FormControlLabel value="Onestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Onestar</span>} />
                                            <FormControlLabel value="Twostar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Twostar</span>} />
                                            <FormControlLabel value="Threestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Threestar</span>} />
                                            <FormControlLabel value="Fourstar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Fourstar</span>} />
                                            <FormControlLabel value="Fivestar" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Fivestar</span>} />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                                <td>
                                    {reviewEdits.oldData.ratingField.map((data: any) => {
                                        switch (value) {
                                            case 'Onestar':
                                                return data.name === 'oneStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Twostar':
                                                return data.name === 'twoStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Threestar':
                                                return data.name === 'threeStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fourstar':
                                                return data.name === 'fourStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fivestar':
                                                return data.name === 'fiveStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            default:
                                                return '-';
                                        }
                                    })}
                                </td>
                                
                                <td>
                                    {reviewEdits.newData.ratingField.map((data: any) => {
                                        switch (value) {
                                            case 'Onestar':
                                                return data.name === 'oneStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Twostar':
                                                return data.name === 'twoStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Threestar':
                                                return data.name === 'threeStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fourstar':
                                                return data.name === 'fourStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            case 'Fivestar':
                                                return data.name === 'fiveStar' ? data.ratingFields.map((item: any) => <p style={styles.includes('ratingFields') ? { color: styles.includes('ratingFields') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('ratingFields') ? "red" : "gray", fontWeight: 700 }}>{item.field}</p>) : null;
                                            default:
                                                return '-';
                                        }
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AssignedProductHistoryView