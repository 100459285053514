import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/ApiResponse.interface'
import { IRatingField } from './interfaces/review.interface'

export class RatingFieldService extends BaseService {
  public async addRatingField(userInformation: IRatingField): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ADD_RATINGFIELD,
        { ...userInformation },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateRatingField(ratingFieldInformation: IRatingField): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.put(
        API_ENDPOINTS.UPDATE_RATINGFIELD + ratingFieldInformation._id,
        ratingFieldInformation,
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async multipleSearchProduct(
    status: string,
    pageNo: number,
    filtervalues: any,
    orderbyValues?: string,
    sortType?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    sortType = sortType ? sortType : ''
    const token = 'Bearer ' + localStorage.getItem('accessToken')

    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.GET_AlL_TEMPLATE +
          `?field=${filtervalues.filed ? filtervalues.name : ''}&status=${filtervalues.status}&sortName=${
            orderbyValues ? orderbyValues : ''
          }&sort=${sortType}&limit=10&page=${pageNo}`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getRatingFields(
    data?: any,
    status?: string | any,
    sortName?: string,
    sort?: string,
    page?: number
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.GET_All_RATINGFIELD}?field=&status=${status ? status : ''}&page=${
          page ? page : 1
        }&limit=10&sortName=${sortName ? sortName : ''}&sort=${sort ? sort : '-1'}`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getNewRatingFields(status?: boolean, page?: number): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.GET_All_RATINGFIELD}?field=&status=${status}&page=${page}&limit=50`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getOneRatingField(id: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_RATINGFIELD + id, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async deleteRatingField(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.delete('inventory/deleteReview', {
        data: { erp_prod_id: code }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
