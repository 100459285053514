import appContext from '../../context/app.context';
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

type SidenavbarProps = {
    label: string;
    to: string,
    isActive?: string[]
    icon?: string
    coloricon?: string
    disabled?: boolean
    onClick?: () => void
    demo?: any;
    subto?: any;
    subicon?: any;
}


const SideMenuItems: React.FC<SidenavbarProps> = ({ icon, coloricon, to, label, disabled, demo, subto, subicon }) => {
    const { setSpinnerVisibility } = useContext(appContext);
    const [value, setValue] = useState(false)
    const handleClickOpen = (_e: any) => {

        setSpinnerVisibility(false)
        setValue(!value)
    }
    return (
        <div className={disabled ? "side-menu-item cursor-notAllow" : "side-menu-item"} >
            {/* isActive={() => productSubPaths.includes(pathname)} */}
            <NavLink className={disabled ? 'menu-link disabled-link' : 'menu-link'} to={to} onClick={handleClickOpen}>
                <div className='menu-ic'>
                    <img className='menu-img' alt='menu-img' src={icon} />
                    <img className='menu-color-img' alt='color-img' src={coloricon} />
                </div>
                <div className="menu-title">
                    {label}
                </div>
                <div className='subicon'>
                    <span onClick={handleClickOpen}>{subicon}</span>

                </div>
            </NavLink>
            <div>
                {value === true && subto !== undefined ?
                    <NavLink to={subto} className='subMenu'>
                        <div >{demo}</div>
                    </NavLink>

                    : ""}
            </div>
        </div>
    )

}

export default SideMenuItems