import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
// import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PoorvikaSmLogo from 'assets/images/poorvika-sm-logo.png'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import profileIcon from 'assets/icons/sidemenu/profile.svg'
import Users from 'assets/icons/sidemenu/users.svg'
// import Accounts from 'assets/icons/sidemenu/accounts.svg'
// import AccountsColor from 'assets/icons/sidemenu/accounts-color.svg'
import Review from 'assets/icons/sidemenu/product-list.svg'
import ReviewColor from 'assets/icons/sidemenu/product-list-color.svg'
import HomeIcon from 'assets/icons/sidemenu/store.svg'
import HomeColorIcon from 'assets/icons/sidemenu/store-color.svg'
import profileColorIcon from 'assets/icons/sidemenu/profile-color.svg'
import UsersColorIcon from 'assets/icons/sidemenu/users-color.svg'
import BellIcon from 'assets/icons/sidemenu/bell-icon.svg'
import PoColorIcon from 'assets/icons/sidemenu/po-color.svg'
import POIcon from 'assets/icons/sidemenu/po.svg'
import StoreColorIcon from 'assets/icons/sidemenu/order-color.svg'
import StoreIcon from 'assets/icons/sidemenu/order.svg'
import SideMenuItems from 'components/sidemenuItems'
import { dashboardPath } from 'common/constant'
import Logout from 'components/Logout/Logout'
import { AiFillCaretDown } from "react-icons/ai";
const SideMenu: React.FC = () => {
  const role = localStorage.getItem('role')

  const isAdmin: boolean = role === 'Admin' || role === 'Super Admin'
  // const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const [notificationMenuVisibility, setNotificationMenuVisibility] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const showAllHistoryHandler = (): void => {
    setNotificationMenuVisibility(!notificationMenuVisibility)
    navigate('/dashboardLayout/notifications')
  }

  /* Handling menu closing activity */
  const handleClose = (): void => {
    setAnchorEl(null)
    setNotificationMenuVisibility(!notificationMenuVisibility)
  }

  const notificationMenuVisibilityHandler = () => setNotificationMenuVisibility(!notificationMenuVisibility)

  return (
    <section className="app-sidebar">
      <div className="menu-header">
        <img className="menu-logo" alt="logo" draggable="false" src={PoorvikaSmLogo} />
        <img className="menu-color-logo" alt="menu-logo" draggable="false" src={PoorvikaLogo} />
      </div>
      <div className="menu-body">
        <div className="menu-list">
          <SideMenuItems
            coloricon={HomeColorIcon}
            icon={HomeIcon}
            isActive={dashboardPath}
            label="Dashboard"
            to="/dashboardLayout/admin/dashboard"
          />
          {isAdmin ? (
            <>
              <SideMenuItems coloricon={UsersColorIcon} icon={Users} label="Users" to="/dashboardLayout/admin/users" />
            </>
          ) : null}

          <SideMenuItems
            coloricon={ReviewColor}
            icon={Review}
            label="Reviews"
            to="/dashboardLayout/admin/reviews"
            demo={"Review History"}
            subicon={<AiFillCaretDown />}
            subto="/dashboardLayout/admin/ReviewHistory"

          />
          <div className="admin-seller-listmenu">
            <SideMenuItems
              coloricon={profileColorIcon}
              icon={profileIcon}
              label="RatingFields"
              to="/dashboardLayout/admin/ratingFields"
              demo={"RatingFields History"}
              subicon={<AiFillCaretDown />}
              subto="/dashboardLayout/admin/RatingFieldHistory"
            />
            <SideMenuItems
              coloricon={StoreColorIcon}
              icon={StoreIcon}
              label="Templates"
              to="/dashboardLayout/admin/ratingTemplates"
              demo={"Templates History"}
              subicon={<AiFillCaretDown />}
              subto="/dashboardLayout/admin/TemplateHistoryPage"
            />
            <SideMenuItems
              coloricon={PoColorIcon}
              icon={POIcon}
              label="Assign To Products"
              to="/dashboardLayout/admin/productAssign"
              // demo={"Assign To Products History"}
              // subicon={<AiFillCaretDown />}
              // subto="/dashboardLayout/admin/AssignedProductHistory"
            />
          </div>
          {/* <SideMenuItems
            coloricon={profileColorIcon}
            icon={profileIcon}
            label="Question and Answers"
            to="/dashboardLayout/admin/usersRoles"
          /> */}
          {/* {isAdmin ? (
            <>
              <SideMenuItems
                coloricon={AccountsColor}
                icon={Accounts}
                label="Users & Roles"
                to="/dashboardLayout/admin/usersRoles"
              />
            </>
          ) : null} */}
        </div>
        <div className="menu-footer">
          {isAdmin ? (
            <div className="admin-logout-wrapper">
              <img
                alt="admin-footer"
                className="menu-footer-ic"
                draggable="false"
                src={BellIcon}
                onClick={notificationMenuVisibilityHandler}
              />
              <Logout />
            </div>
          ) : (
            <>
              <img
                alt="admin-footer"
                className="menu-footer-ic"
                draggable="false"
                src={BellIcon}
                onClick={notificationMenuVisibilityHandler}
              />
            </>
          )}
          <Menu
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            anchorEl={anchorEl}
            id="basic-menu"
            open={notificationMenuVisibility}
            onClose={handleClose}
          >
            <div className="notification-menu">
              <div className="header-and-actions">
                <div className="notification-header">
                  <h1>Recent Notifications</h1>
                </div>
                <div className="action">
                  <p>Mark all as Read</p>
                </div>
              </div>

              <div className="view-all-notifications">
                <p onClick={showAllHistoryHandler}>ALL NOTIFICATIONS</p>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </section>
  )
}

export default SideMenu