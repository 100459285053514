import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/ApiResponse.interface'
import { IReviewData, ReviewArgs } from './interfaces/review.interface'

const encodeGetParams = (params: any) => {
  return Object.entries(params)
    .map((key: any) =>
      key
        .map((k: any) => {
          k = typeof k === 'object' ? JSON.stringify(k) : k
          return encodeURIComponent(k)
        })
        .join('=')
    )
    .join('&')
}
export class ReviewService extends BaseService {
  public async multipleSearchProduct(
    pageNo: number | string,
    filtervalues: ReviewArgs,
    orderbyValues?: string,
    sortType?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    const newObj = {
      itemCode: filtervalues.itemCode,
      productCode:filtervalues.productCode,
      customerId: filtervalues.customerId,
      fromDate: filtervalues.fromDate,
      toDate: filtervalues.toDate,
      publish: filtervalues.publish,
       edit_status:filtervalues.edit_status,
      rating: filtervalues.rating,
      sortName: orderbyValues,
      sort: sortType,
      limit: 10,
      page: pageNo
    }

    const token = 'Bearer ' + localStorage.getItem('accessToken')
    const queryString = encodeGetParams(newObj)

    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_All_REVIEW + '?' + queryString, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async addReview(userInformation: IReviewData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ADD_REVIEW,
        { ...userInformation },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getServiceProductListPimAPI(productName?: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClientPIM.get(
        API_ENDPOINTS.PRODUCT_LIST + `?code=${productName}&limit=10&page=1&status=&sortName=&sort=`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getReviewHistory( pageNo : number): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/review?limit=10&page=${pageNo}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getReviewHistoryView(id:any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/review/id/${id}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getTemplateHistory(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/template`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getTemplateHistoryView(id:any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/templateByid/${id}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  // public async getReviewHistoryTableView(): Promise<ISuccessResponse | IErrorResponse> {
  //   try {
  //     const { data } = await this.httpClient.get(
  //       API_ENDPOINTS.REVIEW_HISTORY+ `/review/621df84840c8b9f8dddc73af`,
  //       {
  //         headers: {
  //           channel: 'ONLINE',
  //           locale: 'en-IN'
  //         }
  //       }
  //     )
  //     return data
  //   } catch (error) {
  //     return parseAxiosError(error as AxiosError)
  //   }
  // } 

  public async getRatingFieldHistory( pageNo : number): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/ratingField?page=${pageNo}&limit=10`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getRatingHistoryView(id:any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.REVIEW_HISTORY+ `/ratingField/id/${id}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
 

  public async getServiceProductCodePimAPI(PRODUCT_MODEL?: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClientPIM.get(
        API_ENDPOINTS.PRODUCT_MODEL + `?code=${PRODUCT_MODEL}&limit=10&page=1&status=1&sortName=&sort=`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async updateReview(reviewInformation: IReviewData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.put(
        API_ENDPOINTS.UPDATE_REVIEW + reviewInformation._id,
        reviewInformation,
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getReview(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_All_REVIEW, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getOneReview(id: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_REVIEW + id, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductsName(name: string): Promise<ISuccessResponse | IErrorResponse> {
    const token = 'Bearer ' + localStorage.getItem('accessToken') || ''

    try {
      const { data } = await this.httpClient.get(
        'admin/variant-product-filter/autoSearch?limit=10&page=1&productName=' + name,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getCustomersName(name: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      // console.clear()
      const { data } = await this.httpClientCRM.get(API_ENDPOINTS.GET_CUSTOMER_LIST + `?name=${name}&page=1&limit=10`)
      // console.log(data, '+_+_+_+_+_+_')
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
