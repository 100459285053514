import MaterialTab from 'components/MaterialTab'
import React, { useEffect ,useContext} from 'react'
import appContext from '../../context/app.context'
import { RatingTemplateService } from 'services/ratingTemplate.service'
// import Swal from 'sweetalert2'

import './styles.scss'

type Tprops = {
  reviewEdits?: any
  onClick?: () => void
  handleCloseUpdated?: () => void
}

export const AssignPage: React.FC<Tprops> = ({ reviewEdits, onClick, handleCloseUpdated }) => {
  const templateService = new RatingTemplateService()
  const { setSpinnerVisibility } = useContext(appContext)
  const getTemplateList = async () => {
    setSpinnerVisibility(true);
    try {
      
      const { data } = await templateService.getRatingTemplates()
      setSpinnerVisibility(false)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setSpinnerVisibility(false)
    getTemplateList()
  }, [])

  return (
    <div className="user-list-page">
    
      <MaterialTab reviewEdits={reviewEdits} onClick={onClick} handleCloseUpdated={handleCloseUpdated} />
          
    </div>
  )
}
