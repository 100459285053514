import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


const ColorButton = styled(Button)(() => ({
  color: '#fff',

  backgroundColor: '#ff8e33',
  '&:hover': {
    backgroundColor: '#ff8e33',
  },
}));

 type ButtonProps = {
  label:string
  type: "button" | "submit" | "reset" | undefined
  onClick?:() => void
  startIcon?:React.ReactNode
  endIcon?:React.ReactNode
}



const CustomizedButtons: React.FC<ButtonProps>   = ({label,  onClick, type, startIcon, endIcon}): React.ReactElement => {

  return (
    <Stack spacing={2} direction="row">

      <ColorButton onClick={onClick} endIcon={ endIcon ? endIcon : null} startIcon={ startIcon ? startIcon : null} type={type} variant="contained">{label}</ColorButton>

    </Stack>
  );
}

export default CustomizedButtons