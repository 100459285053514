import React from 'react'
import ActionButton from 'components/ActionButton'
import CloseIcon from '@mui/icons-material/Close'


type Tprops = {
  show: boolean
  reviewData: any
  handleClose: () => void
}

const ReviewDetails: React.FC<Tprops> = ({ handleClose, show, reviewData }) => {

  return (
    <div className="inventory-add-form">
      <div className={`Modal ${show ? 'Show' : ''}`}>
        <div className="handle-close-ic" onClick={handleClose}>
          <CloseIcon className="close-ic" />
        </div>
        <p className="title-content">RatingField Details</p>
        <div className="inventory-form">
          <div className="form-data">
            <span className="form-data-det">Field Name </span>
            <span className="form-data-det">{reviewData.field}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Status </span>
            <span className="form-data-det"><span className={reviewData.status? 'status-green ' : 'status-red'}>{reviewData.status ? 'Active' : 'InActive'}</span></span>
          </div>
          
        
          <div className="form-btn-wrap">
            <ActionButton color="outlined" label="Close" type="button" onClick={handleClose} />
            {/* <ActionButton label="OK"  onClick={handleClose}  /> */}
          </div>
        </div>
      </div>
      <div className={`Overlay ${show ? 'Show' : ''}`} />
    </div>
  )
}

export default ReviewDetails
