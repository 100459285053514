import React from "react"

const ThemeContext = React.createContext({
    themePrimary: '#ff9000',
    themeLighterAlt: '#fffbf5',
    themeLighter: '#ffedd6',
    themeLight: '#ffdeb3',
    themeTertiary: '#ffbd66',
    themeSecondary: '#ff9e1f',
    themeDarkAlt: '#e68200',
    themeDark: '#c26e00',
    themeDarker: '#8f5100',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
 
})

export default ThemeContext