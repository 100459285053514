import ActionButton from 'components/ActionButton'
import ReviewDetails from 'containers/forms/ReviewDetails'
import moment from 'moment'
import React, { useEffect, useRef, useState, useContext } from 'react'

// import { useNavigate } from 'react-router-dom'
import { IReviewData, ReviewArgs } from 'services/interfaces/review.interface'
import { ReviewService } from 'services/review.service'
import RatingStars from 'components/RatingStar'
import './styles.scss'
import { Autocomplete, Dialog, DialogContent, FormControl, Pagination, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { showNotification, STATUS } from 'common/constant'
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/ApiResponse.interface'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
//import Typography from '@mui/material/Typography'

// import UserAddEditForm from 'containers/forms/TemplateEditForm'
import CloseIcon from '@mui/icons-material/Close'
// import ReviewAddEditForm from 'containers/forms/ReviewAddEditForm'
import ReviewAddAndEdit from './ReviewAddAndEdit'
import appContext from '../../context/app.context'

const ratingList = ['One', 'Two', 'Three', 'Four', 'Five']
const approvedList = ['Approved', 'UnApproved']
const ReviewapprovedList = ['Approve For Later', 'Invalid']

export const ReviewPage: React.FC = () => {
  const [showReview, setshowReview] = useState<boolean>(false)
  const [reviewList, setReviewList] = useState<IReviewData[] | []>([])
  const [reviewEdit, setReviewEdit] = useState<any | []>([])
  // const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<any>({})
  const [isEditData, setIsEditData] = useState<any>()
  const [pageCount, setPageCount] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageNo, setPageNo] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortType, setSortingType] = useState<string>('1')
  // const [productValue, setProductValue] = useState('')
  const [customerValue, setcustomerValue] = useState('')
  // const [products, setProducts] = useState<any>([])

  const [customers, setCustomers] = useState<any>([])
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const [fromDateStatus, setFromDateStatus] = useState<boolean>(false)
  const [toDateStatus, setToDateStatus] = useState<boolean>(true)
  const [filterRating, setFilterRating] = useState<any>('')
  const [filterPublish, setFilterPublish] = useState<any>('')
  const clearBtn = useRef()
  const [open, setOpen] = React.useState(false)
  const { setSpinnerVisibility } = useContext(appContext);
  const [filteredProducts, setFilteredProducts] = useState('');
  const [selectProductCode, setselectProductCode] = useState<any>(null)
  const [productCodeValue, setProductCodeValue] = useState('')
  const [productsCode, setProductsCode] = useState<any>([])
  const [editFilter, setEditFilter] = useState<any>('')

  // const handleAutocompleteChange = (event: any, newValue: any) => {
  //   setSelectedProduct(newValue);
  //   setFilteredProducts('');

  // };
  const handleProductCodeChange = (event: any, newValue: any) => {
    setselectProductCode(newValue);

  };
  const handleInputChange = (event: any) => {
    setFilteredProducts(event.target.value);
    // setSelectedProduct('')
  };
  const handleClose = () => {
    setOpen(false)
  }

  const reviewService = new ReviewService()

  const getreviewList = async () => {
    try {
      setSpinnerVisibility(true);
      const reviewResponse: ISuccessResponse = (await reviewService.getReview()) as ISuccessResponse
      if (reviewResponse.statusCode === 200) {
        setPageCount(Math.ceil(Number(reviewResponse.count) / 10))
        setTotalCount(reviewResponse.count || 0)
        setReviewList(reviewResponse.data)
        setSpinnerVisibility(false);
      } else {
        setSpinnerVisibility(false);
        showNotification(STATUS.FAILURE, "Too Many Requests")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const multipleProductFilter = async (
    data: ReviewArgs,
    sortingColumn: string,
    sortingType: string,
    pagination: string | number = '',
    filter = false
  ) => {
    try {
      let pageNumber = pagination === '' ? pageNo : pagination
      setSpinnerVisibility(true)
      if (filter) {
        setPageNo(1)
        pageNumber = 1
        // setSpinnerVisibility(false)
      }

      const Pimproducts: ISuccessResponse | IErrorResponse = (await reviewService.multipleSearchProduct(
        pageNumber,
        data,
        sortingColumn,
        sortingType
      )) as ISuccessResponse

      const getpimproducts = Pimproducts
      setReviewList(getpimproducts.data || [])
      setTotalCount(getpimproducts.count || 0)
      setPageCount(Math.ceil(Number(getpimproducts.count) / 10) || 0)
      setSpinnerVisibility(false)
    } catch (error: any) {
      // alert(error)
      showNotification(STATUS.FAILURE, error.message)
    }
  }

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const type = sortType === '1' ? '-1' : '1'
    setSortColumn(columnName)
    setSortingType(type)
    let rate: any = '',
      edit_status: any = '',
      publish: any = ''
    // const itemCode = selectedProduct && Object.keys(selectedProduct)?.length > 0 ? selectedProduct.item_code : filteredProducts
    const itemCode = filteredProducts ? filteredProducts : ''
    const productCode = selectProductCode && Object.keys(selectProductCode)?.length > 0 ? selectProductCode.code : ''
    const customerId = selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''

    if (filterPublish) {
      publish = filterPublish === 'Approved' ? true : false
    }

    if (editFilter) {
      if (editFilter === 'Invalid') edit_status = 'IV'
      else if (editFilter === 'Approve For Later')
        edit_status = 'AFL'
      else if (editFilter === '') edit_status = ''
    }

    if (filterRating) {
      if (filterRating === 'One') rate = 1
      else if (filterRating === 'Two') rate = 2
      else if (filterRating === 'Three') rate = 3
      else if (filterRating === 'Four') rate = 4
      else if (filterRating === 'Five') rate = 5
    }

    const data: any = {
      publish,
      itemCode,
      edit_status,
      productCode,
      customerId,
      rating: rate,
      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : ''
    }
    multipleProductFilter(data, columnName, type, pageNo)
  }

  const getSerialNumber = (index: number) => {
    if (pageNo === 1 && index < 9) return index + 1
    else if (index === 9) return pageNo + '0'
    return `${pageNo - 1}${index + 1}`
  }

  const getPageResult = () => {
    if (totalCount > 10 && pageNo * 10 < totalCount) return pageNo * 10
    return totalCount
  }

  useEffect(() => {
    setFromDateStatus(false)
    getreviewList()
    setSpinnerVisibility(true)
    // let rate: any = '',
    //   publish: any = ''
    // const itemCode = selectedProduct && Object.keys(selectedProduct)?.length > 0 ? selectedProduct.item_code : ''
    // const customerId = selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''
    // if (filterPublish) {
    //   publish = filterPublish === 'Approved' ? true : false
    // }

    // if (filterRating) {
    //   if (filterRating === 'One') rate = 1
    //   else if (filterRating === 'Two') rate = 2
    //   else if (filterRating === 'Three') rate = 3
    //   else if (filterRating === 'Four') rate = 4
    //   else if (filterRating === 'Five') rate = 5
    // }

    // const data: any = {
    //   publish,
    //   itemCode,
    //   customerId,
    //   rating: rate,
    //   fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
    //   toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : ''
    // } 
    // multipleProductFilter(data, sortColumn, sortType, pageNo)
  }, [])

  const handleCloseUpdated = () => {
    const columnName = ''
    const type = sortType === '1' ? '-1' : '1'
    setSortColumn('')
    setSortingType(type)
    let rate: any = '',
      publish: any = '',
      edit_status: any = ''
    const itemCode = filteredProducts ? filteredProducts : ''
    const productCode = selectProductCode && Object.keys(selectProductCode)?.length > 0 ? selectProductCode.code : ''
    const customerId = selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''

    if (filterPublish) {
      publish = filterPublish === 'Approved' ? true : false
    }
    if (editFilter) {
      if (editFilter === 'Invalid') edit_status = 'IV'
      else if (editFilter === 'Approve For Later')
        edit_status = 'AFL'
      else if (editFilter === '') edit_status = ''
    }

    if (filterRating) {
      if (filterRating === 'One') rate = 1
      else if (filterRating === 'Two') rate = 2
      else if (filterRating === 'Three') rate = 3
      else if (filterRating === 'Four') rate = 4
      else if (filterRating === 'Five') rate = 5
    }

    const data: any = {
      publish,
      edit_status,
      itemCode,
      productCode,
      customerId,
      rating: rate,
      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : ''
    }
    multipleProductFilter(data, columnName, type, pageNo)
    setOpen(false)
  }
  return (
    <div className="listed-product-layout">
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>Review Table</p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <div className="filter-head ">
            {/* <div className="filter-item">
              <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
            </div> */}
            <div className="filter-item">
              <Autocomplete
                value={selectProductCode}
                // onChange={async (event: any, product: any) => {
                //   setSelectedProduct(product || {})
                // }}
                onChange={handleProductCodeChange}
                style={{ width: 300 }}
                inputValue={productCodeValue}
                onInputChange={async (event, newInputValue) => {
                  if (newInputValue !== 'undefined') {
                    setProductCodeValue(newInputValue)
                    const productsCode = await reviewService.getServiceProductCodePimAPI(newInputValue)
                    if (productsCode.status === 200) setProductsCode(productsCode?.data?.content?.result || [])
                  }
                }}
                id="controllable-states-demo"
                options={productsCode}
                getOptionLabel={(option: any) => option.name || ''}
                renderInput={(params) => <TextField {...params} size="small" label="Enter Product" />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name}
                    </li>
                  )
                }}
              />
            </div>
            <div className="filter-item">
              <TextField
                fullWidth
                // id="outlined-basic"
                id="validation-outlined-input"
                value={filteredProducts}
                size="small"
                // inputValue={productValue}   
                type={"number"}
                onChange={handleInputChange}
                label={"Item Code"}
                sx={{ "label": { border: "1px solid #fff", borderRadius: "20px" }, borderRadius: "4px" }}
                variant="outlined"
              />
            </div>
            {/* <div className="filter-item">
              <Autocomplete
                value={selectedProduct}
                // onChange={async (event: any, product: any) => {
                //   setSelectedProduct(product || {})
                // }}
                onChange={handleAutocompleteChange}
                style={{ width: 300 }}
                inputValue={productValue}
                onInputChange={async (event, newInputValue) => {

                  if (newInputValue !== 'undefined') {
                    setProductValue(newInputValue)
                    const products = await reviewService.getServiceProductListPimAPI(newInputValue)
                    if (products.status === 200) setProducts(products?.data?.content?.result || [])

                  }
                }}
                id="controllable-states-demo"
                options={products}
                getOptionLabel={(option: any) => option.name || ''}
                renderInput={(params) => <TextField {...params} size="small" label="Enter Product Variant" />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name}
                    </li>
                  )
                }}
              />
            </div> */}
            <div className="filter-item">
              <Autocomplete
                value={selectedCustomer}
                onChange={async (event: any, customer: any) => {
                  setSelectedCustomer(customer)
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.customer_id}>
                      {option.name}
                    </li>
                  )
                }}
                style={{ width: 300 }}
                inputValue={customerValue}
                onInputChange={async (event, newInputValue) => {
                  if (newInputValue !== 'undefined') {
                    setcustomerValue(newInputValue)
                    const customers = await reviewService.getCustomersName(newInputValue)
                    if (customers.data !== null) setCustomers(customers.data || [])
                  }
                }}
                id="controllable-states-demo"
                options={customers}
                getOptionLabel={(option: any) => option.name || ''}
                renderInput={(params) => <TextField {...params} size="small" label="Enter Customer" />}
              />
            </div>
            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={ratingList || []}
                  renderInput={(params) => (
                    <TextField {...params} label="Rating Star" size="small" variant="outlined" />
                  )}
                  style={{ width: 230 }}
                  value={filterRating}
                  onChange={(e, val) => setFilterRating(val)}
                />
              </FormControl>
            </div>
            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={approvedList || []}
                  renderInput={(params) => <TextField {...params} label="Approve Status" size="small" variant="outlined" />}
                  style={{ width: 230 }}
                  value={filterPublish}
                  onChange={(e, val) => setFilterPublish(val)}
                />
              </FormControl>
            </div>
            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={ReviewapprovedList || []}
                  renderInput={(params) => <TextField {...params} label="Review Status" size="small" variant="outlined" />}
                  style={{ width: 230 }}
                  value={editFilter}
                  onChange={(e, val) => setEditFilter(val)}
                />
              </FormControl>
            </div>
            <div className="filter-item">
              <DesktopDatePicker
                label="From"
                inputFormat="DD/MM/YYYY"
                value={fromDate}
                disableFuture
                disabled={fromDateStatus}
                onChange={(newValue: Date | null) => {
                  if (newValue) {
                    setFromDate(newValue)
                    newValue ? setToDateStatus(false) : setToDateStatus(true)
                  }
                  if (newValue === null) {
                    setToDateStatus(true)
                    setToDate(null)
                  }
                }}
                renderInput={(params: any) => <TextField size="small" {...params} />}
              />
            </div>
            <div className="filter-item">
              <DesktopDatePicker
                label="To"
                inputFormat="DD/MM/YYYY"
                value={toDate}
                minDate={fromDate}
                disableFuture
                disabled={toDateStatus}
                onChange={(newValue: Date | null) => {
                  setToDate(newValue)
                }}
                renderInput={(params: any) => <TextField size="small" {...params} />}
              />
            </div>
            <div className="filter-item filter-action">
              <ActionButton
                label="Apply"
                onClick={() => {

                  if (fromDate && toDate === null) {
                    showNotification(STATUS.FAILURE, 'Please select To End date')
                  } else {
                    setSpinnerVisibility(true)
                    let rate: any = '',
                      publish: any = '',
                      edit_status: any = ''
                    // const itemCode = selectedProduct && Object.keys(selectedProduct)?.length > 0 ? selectedProduct.item_code : filteredProducts
                    const itemCode = filteredProducts ? filteredProducts : ''
                    const productCode = selectProductCode && Object.keys(selectProductCode)?.length > 0 ? selectProductCode.code : ''
                    const customerId =
                      selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''
                    if (filterPublish) {
                      publish = filterPublish === 'Approved' ? true : false
                      setSpinnerVisibility(false)
                    }
                    if (editFilter) {
                      if (editFilter === 'Invalid') edit_status = 'IV'
                      else if (editFilter === 'Approve For Later')
                        edit_status = 'AFL'
                      else if (editFilter === '') edit_status = ''
                    }

                    if (filterRating) {
                      if (filterRating === 'One') rate = 1
                      else if (filterRating === 'Two') rate = 2
                      else if (filterRating === 'Three') rate = 3
                      else if (filterRating === 'Four') rate = 4
                      else if (filterRating === 'Five') rate = 5
                      setSpinnerVisibility(false)
                    }

                    const data: any = {
                      publish,
                      edit_status,
                      itemCode,
                      productCode,
                      customerId,
                      rating: rate,
                      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
                      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
                    }
                    multipleProductFilter(data, sortColumn, sortType, pageNo, true)
                  }
                }}
              />
              <ActionButton
                label="Clear"
                ref={clearBtn}
                onClick={() => {
                  setFilterRating('')
                  setFilterPublish('')
                  setEditFilter('')
                  // setSelectedProduct({})
                  setSelectedCustomer({})
                  setFilteredProducts('')
                  setselectProductCode('')
                  setFromDate(null)
                  setToDate(null)
                  setToDateStatus(true)
                  const data: any = {
                    publish: '',
                    itemCode: '',
                    productCode: '',
                    customerId: '',
                    rating: '',
                    fromDate: '',
                    toDate: ''
                  }
                  multipleProductFilter(data, sortColumn, sortType, pageNo, true)
                  getreviewList()
                }
                }
              />
            </div>
          </div>
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Customer</th>
                <th>Item Code</th>
                <th>Product</th>
                <th>Review title</th>
                <th>
                  Rating
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'rating'
                        ? (faSort as IconProp)
                        : sortType === '-1'
                          ? (faSortDown as IconProp)
                          : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('rating', sortType)}
                  />
                </th>
                <th>
                  Status
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'publish'
                        ? (faSort as IconProp)
                        : sortType === '-1'
                          ? (faSortDown as IconProp)
                          : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('publish', sortType)}
                  />
                </th>
                <th>
                  Date
                  {/* <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'createdAt'
                        ? (faSort as IconProp)
                        : sortType === '-1'
                          ? (faSortDown as IconProp)
                          : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('createdAt', sortType)}
                  /> */}
                </th>
                {/* <th>
                  Modified Date
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'updatedAt'
                        ? (faSort as IconProp)
                        : sortType === '-1'
                          ? (faSortDown as IconProp)
                          : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('updatedAt', sortType)}
                  />
                </th> */}
                <th>Actions</th>
                {/* <th>History</th> */}
              </tr>
            </thead>
            {reviewList.length ? (
              <tbody>
                {reviewList.map((reviewData: IReviewData, index: number) => {
                  return (
                    <tr key={reviewData._id} className="user-list-tbody">
                      <td>{getSerialNumber(index)}</td>
                      <td>{reviewData.customerName}</td>
                      <td>{reviewData.itemCode}</td>
                      <td>{reviewData.productName}</td>
                      <td>{reviewData.title}</td>
                      <td>
                        <RatingStars stars={reviewData.rating} />
                      </td>
                      <td>
                        {' '}
                        <span className={reviewData.publish ? 'status-green' : 'status-red'}>
                          {reviewData.publish ? 'Approved' : 'UnApproved'}
                        </span>
                      </td>
                      <td><b>Created Date:</b> {moment(reviewData.createdAt).format('DD-MM-YYYY hh:mm:ss A')}<br /><br />
                        <b>Modified Date:</b> {moment(reviewData.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}</td>
                      <td>
                        <div className="product-col">
                          <div style={{ display: 'flex' }}>
                            <ActionButton
                              label="View"
                              onClick={() => {
                                setIsEditData(reviewData)
                                setshowReview(true)
                              }}
                            />
                            {/* <ActionButton label="Edit" onClick={() => navigate('edit/' + reviewData._id)} /> */}
                            <ActionButton
                              label="Edit"
                              onClick={() => {
                                setOpen(true)
                                setReviewEdit(reviewData)
                              }}
                            />
                          </div>

                          {/* <ActionButton
                            label="History"
                            onClick={() => {
                              // setIsEditData(reviewData)
                              // setshowReview(true)
                            }}
                          /> */}
                        </div>
                      </td>
                      {/* <td>
                      <ActionButton
                            label="History"
                            onClick={() => {
                              setOpen(true)
                              setReviewEdit(reviewData)
                            }}
                          />
                      </td> */}
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data" colSpan={12}>
                    No data to display
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="all-products-count">
            {' Showing Result '}
            {getPageResult()} of {totalCount}
          </div>
          <div className="all-products-pagination">
            <Pagination
              className="all-products-pagination"
              count={pageCount}
              shape="rounded"
              variant="outlined"
              page={pageNo}
              onChange={(event: any, value: number) => {
                setPageNo(value)
                let rate: any = '',
                  edit_status: any = '',
                  publish: any = ''
                // const itemCode =
                //   selectedProduct && Object.keys(selectedProduct)?.length > 0 ? selectedProduct.item_code : filteredProducts
                const itemCode = filteredProducts ? filteredProducts : ''
                const productCode = selectProductCode && Object.keys(selectProductCode)?.length > 0 ? selectProductCode.code : ''
                const customerId =
                  selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''

                if (filterPublish) {
                  publish = filterPublish === 'Approved' ? true : false
                }
                if (editFilter) {
                  if (editFilter === 'Invalid') edit_status = 'IV'
                  else if (editFilter === 'Approve For Later')
                    edit_status = 'AFL'
                  else if (editFilter === '') edit_status = ''
                }

                if (filterRating) {
                  if (filterRating === 'One') rate = 1
                  else if (filterRating === 'Two') rate = 2
                  else if (filterRating === 'Three') rate = 3
                  else if (filterRating === 'Four') rate = 4
                  else if (filterRating === 'Five') rate = 5
                }

                const data: any = {
                  publish,
                  itemCode,
                  edit_status,
                  productCode,
                  customerId,
                  rating: rate,
                  fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
                  toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : ''
                }
                multipleProductFilter(data, sortColumn, sortType, value)

              }}
            />
          </div>
        </div>

        {showReview ? (
          <ReviewDetails
            reviewData={isEditData}
            show={showReview}
            handleClose={() => {
              setshowReview(!showReview)
              let rate: any = '',
                edit_status: any = '',
                publish: any = ''
              // const itemCode =
              // selectedProduct && Object.keys(selectedProduct)?.length > 0 ? selectedProduct.item_code : filteredProducts
              const itemCode = filteredProducts ? filteredProducts : ''
              const productCode = selectProductCode && Object.keys(selectProductCode)?.length > 0 ? selectProductCode.code : ''
              const customerId =
                selectedCustomer && Object.keys(selectedCustomer)?.length > 0 ? selectedCustomer.customer_id : ''

              if (filterPublish) {
                publish = filterPublish === 'Approved' ? true : false
              }

              if (editFilter) {
                if (editFilter === 'Invalid') edit_status = 'IV'
                else if (editFilter === 'Approve For Later')
                  edit_status = 'AFL'
                else if (editFilter === '') edit_status = ''
              }

              if (filterRating) {
                if (filterRating === 'One') rate = 1
                else if (filterRating === 'Two') rate = 2
                else if (filterRating === 'Three') rate = 3
                else if (filterRating === 'Four') rate = 4
                else if (filterRating === 'Five') rate = 5
              }

              const data: any = {
                publish,
                edit_status,
                itemCode,
                productCode,
                customerId,
                rating: rate,
                fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
                toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : ''
              }
              multipleProductFilter(data, sortColumn, sortType, pageNo)
            }}
          />
        ) : (
          <></>
        )}
      </div>
        <Dialog
          onClose={handleClose}
          open={open}
          
        >
          <div className="model-wrp review_edit_popup">
            <div>
              <div className="dilog-title dilog-wrp">
                <CloseIcon onClick={handleClose} className="pointer-events" />
              </div>
            </div>
            <DialogContent>
              <ReviewAddAndEdit reviewEdits={reviewEdit} handleCloseUpdated={handleCloseUpdated} onClick={handleClose} />
            </DialogContent>
          </div>
        </Dialog>
    </div>
  )
}
