import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navigate } from 'react-router-dom';
import { store } from 'redux/store'
import { LOGIN, USER } from 'redux/actions/UserActionTypes'
import { Tooltip } from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'

const Logout: React.FC = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>()
  const logoutHandler = () => {
    localStorage.clear()
    store.dispatch({
      type: LOGIN,
      payload: []
    })
    store.dispatch({
      type: USER,
      payload: []
    })

    if (keycloak?.authenticated) {
      keycloak?.logout()
    } else {
      <Navigate to="/" />
    }
  }

  return (
    <Tooltip title="logout">
 
      <NavLink
        className="navbar-item"
        to="/"
      >
       <ExitToAppIcon className="icons-white" onClick={logoutHandler} />
        <button className="logout-btn" onClick={logoutHandler}>
          Logout
        </button>
      </NavLink>
    </Tooltip>
  )
}

export default Logout
