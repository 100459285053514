import React, { useState } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'
import InputFieldWithLabels from 'components/InputFieldWithLabels'
import ActionButton from 'components/ActionButton'
import InputWithDropDown from 'components/InputwithDropDown'
import { UserService } from 'services/user.service'
// import { IUserData } from "services/interfaces/user.interface";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { showNotification, STATUS } from 'common/constant'

// interface formFields extends IUserData {

//     row_added_by: string,
//     row_added_date: string,
//     row_updated_by: string,
//     row_updated_date: string
// }

// type Tprops = {
//     userData: formFields
// }

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  userName: Yup.string().required('Username is required').max(32, 'minimum 32 characters'),
  mobileNumber: Yup.string()
    .required('Mobile number is required')
    .min(10, 'Phone number must be at least 10 Numbers')
    .max(10, 'phone number of 10 digits')
    .matches(phoneRegExp, 'Mobile number is not valid'),

  email: Yup.string().required('Email is required').email('Email ID is not valid'),
  role: Yup.string().required('Role is required')
})
const formOptions = { resolver: yupResolver(validationSchema) }

const roles_list = ['Super Admin', 'Admin', 'User', 'Guest']

const UserAddEditForm: React.FC = () => {
  const [userData] = useState<any>({})
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(formOptions)

  const userService = new UserService()

  const onSubmit = async (data: any) => {
    try {
      data.row_added_by = 'developer'
      data.row_updated_by = 'developer'
      data.mobileNumber = Number(data.mobileNumber)

      if (userData.id) {
        data.id = userData.id
        const updateInventoryResponse = await userService.updateUser(data)
        console.log('ajima', updateInventoryResponse)
      } else {
        const addInventoryResponse: any = await userService.addUser(data)
        if (addInventoryResponse?.error?.response?.data?.statusCode == 403) {
          showNotification(STATUS.FAILURE, addInventoryResponse?.message)
        } else if (addInventoryResponse?.statusCode == 200) {
          showNotification(STATUS.SUCCESS, 'User Insert successfully')
          navigate(-1)
        }
        // console.log(addInventoryResponse)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const navigate = useNavigate()
  return (
    <div className="inventory-add-form">
      <p className="title-content">Add User</p>
      <div className="inventory-form">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '30px' }}>
          <InputFieldWithLabels
            register={register('userName')}
            error={errors.userName?.type !== undefined}
            errorText={errors.userName?.message}
            text="Username"
            maxLength={32}
            defaultValue={userData?.userName}
          />
          <InputFieldWithLabels
            register={register('mobileNumber')}
            maxLength={10}
            error={errors.mobileNumber?.type !== undefined}
            errorText={errors.mobileNumber?.message}
            onChange={(event) => {
              const result = event.target.value.replace(/\D/g, '')
              setValue('mobileNumber', result)
            }}
            text="Mobile Number"
            defaultValue={userData?.mobileNumber}
          />
          <InputFieldWithLabels
            register={register('email')}
            error={errors.email?.type !== undefined}
            errorText={errors.email?.message}
            text="Email"
            defaultValue={userData?.email}
          />
          <InputFieldWithLabels
            register={register('password')}
            error={errors.password?.type !== undefined}
            errorText={errors.password?.message}
            text="Password"
            defaultValue={userData?.password}
            onChange={(event) => {
              const result = event.target.value.replace(/\s/g, '')
              setValue('password', result)
            }}
            type="password"
          />
          <InputFieldWithLabels
            register={register('confirmPassword')}
            error={errors.confirmPassword?.type !== undefined}
            errorText={errors.confirmPassword?.message}
            text="Confirm Password"
            onChange={(event) => {
              const result = event.target.value.replace(/\s/g, '')
              setValue('confirmPassword', result)
            }}
            defaultValue={userData?.password}
            type="password"
          />

          <InputWithDropDown
            dropdown={roles_list}
            register={register('role')}
            error={errors.role?.type !== undefined}
            errorText={errors.role ? errors.role.message : ''}
            text="Role"
            defaultValue={userData?.role}
          />

          <div className="form-btn-wrap">
            <ActionButton label={userData.id ? 'Update' : 'ADD'} type="submit" />
            <ActionButton
              color="outlined"
              label="Cancel"
              type="button"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserAddEditForm
