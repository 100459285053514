import  HeaderLayout  from 'components/Header'
import SideMenu from 'components/SideMenu'
import UserAddEditForm from 'containers/forms/UserAddEditForm'
import RatingFieldEditForm from 'containers/forms/RatingFieldEditForm'
import { RatingFieldPage } from 'pages/RatingFieldPage'
import { UserPage } from 'pages/UserPage'
import { ReviewPage } from 'pages/ReviewPage'
import { TemplatePage } from 'pages/TemplatePage'
import { AssignedListPage } from 'pages/AssignedListPage'
import { AssignPage } from 'pages/AssignPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ReviewAddEditForm from 'containers/forms/ReviewAddEditForm'
import AuthRoute from 'components/AuthRoute'
import TemplateEditForm from 'containers/forms/TemplateEditForm'

import './styles.scss'
import { DashboardPage } from 'pages/DashboardPage'
import ReviewHistory from 'pages/ReviewPage/ReviewHistory'
import RatingFieldHistory from 'pages/RatingFieldPage/RatingFieldHistory'
import { ReviewHistoryTable } from 'pages/ReviewPage/ReviewHistory/ReviewHistoryTable'
import TemplateHistoryPage from 'pages/TemplatePage/TemplateHistoryPage'
import AssignedProductHistory from 'pages/AssignedListPage/AssignedProductHistory'


const AdminLayout: React.FC = () => {
  return (
    <div className="app-layout">
      <SideMenu />
      <section className="app-main">
        <HeaderLayout title='' />
        <Routes>
          <Route element={<AuthRoute />}>
            <Route path="admin/users" element={<UserPage />} />
            <Route path="admin/users/new" element={<UserAddEditForm />} />
            <Route path="admin/users/edit/:id" element={<UserAddEditForm />} />
            <Route path="admin/reviews" element={<ReviewPage />} />
            <Route path="admin/ReviewHistory" element={<ReviewHistory />} />
            <Route path="admin/RatingFieldHistory" element={<RatingFieldHistory />} />
            <Route path="admin/ReviewHistoryTable" element={<ReviewHistoryTable />} />
            <Route path="admin/TemplateHistoryPage" element={<TemplateHistoryPage />} />
            <Route path="admin/AssignedProductHistory" element={<AssignedProductHistory />} />
            <Route path="admin/reviews/new" element={<ReviewAddEditForm />} />
            <Route path="admin/reviews/edit/:id" element={<ReviewAddEditForm />} />
            <Route path="admin/ratingFields" element={<RatingFieldPage />} />
            <Route path="admin/ratingFields/new" element={<RatingFieldEditForm />} />
            <Route path="admin/ratingFields/edit/:id" element={<RatingFieldEditForm />} />
            <Route path="admin/ratingTemplates" element={<TemplatePage />} />
            <Route path="admin/ratingTemplates/new" element={<TemplateEditForm />} />
            <Route path="admin/ratingTemplates/edit/:id" element={<TemplateEditForm />} />
            <Route path="admin/productAssign" element={<AssignedListPage />} />
            <Route path="admin/productAssign/new" element={<AssignPage />} />
            <Route path="admin/productAssign/edit/:id" element={<AssignPage />} />
            <Route path="admin/dashboard" element={<DashboardPage />} />

          </Route>
        </Routes>
      </section>
    </div>
  )
}
export default AdminLayout
