import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import InputFieldWithLabels from 'components/InputFieldWithLabels'
import ActionButton from 'components/ActionButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { RatingTemplateService } from 'services/ratingTemplate.service'
import { useNavigate, useParams } from 'react-router-dom'
import { showNotification, STATUS } from 'common/constant'
import MaterialAutoComplete from 'components/MaterialAutoComplete'
import MaterialDropDown from 'components/MaterialDropDown'
import { RatingFieldService } from 'services/ratingField.service'
import _ from 'lodash'

const validationSchema = Yup.object().shape({
  templateName: Yup.string().required('Template Name is required'),
  oneStar: Yup.array().min(1, 'OneStar must contain at least one item'),
  twoStar: Yup.array().min(1, 'TwoStar must contain at least one item'),
  threeStar: Yup.array().min(1, 'ThreeStar must contain at least one item'),
  fourStar: Yup.array().min(1, 'FourStar must contain at least one item'),
  fiveStar: Yup.array().min(1, 'FiveStar must contain at least one item')
});

const formOptions = { resolver: yupResolver(validationSchema) }

const status_list = ['Enable', 'Disable']

const UserAddEditForm: React.FC = () => {
  const [fieldData, setFieldData] = useState<any>({})
  const [ratingFields, setRatingFields] = useState<any>({})
  const navigate = useNavigate()
  const param = useParams()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm(formOptions)

  console.log('Watching oneStar', watch('oneStar'))

  const ratingTemplateService = new RatingTemplateService()
  const ratingFieldService = new RatingFieldService()

  const onSubmit = async (data: any) => {
    // console.clear()
    let test = ""
    Object.keys(data).forEach((ele) => {
      if (data[ele] === undefined || data[ele].length === 0)
        test = "error"
    })
    if (test === "error") {
      showNotification(STATUS.FAILURE, 'All Fields are mandatory')
    } else {
      try {
        const templateInfo: any = {
          templateName: data.templateName,
          ratings: [
            {
              name: 'oneStar',
              ratingFields: data.oneStar.map((item: any) => item._id)
            },
            {
              name: 'twoStar',
              ratingFields: data.twoStar.map((item: any) => item._id)
            },
            {
              name: 'threeStar',
              ratingFields: data.threeStar.map((item: any) => item._id)
            },
            {
              name: 'fourStar',
              ratingFields: data.fourStar.map((item: any) => item._id)
            },
            {
              name: 'fiveStar',
              ratingFields: data.fiveStar.map((item: any) => item._id)
            }
          ],
          status: data.status === 'Enable' ? true : false
        }

        console.log(templateInfo)

        if (fieldData._id) {
          templateInfo._id = fieldData._id
          const ratingFieldResponse = await ratingTemplateService.updateRatingTemplate(templateInfo)

          if (ratingFieldResponse.statusCode === 200) {
            showNotification(STATUS.SUCCESS, 'Rating Field Updated successfully')
            navigate(-1)
          } else showNotification(STATUS.FAILURE, 'Rating Field Not Updated')
        } else {
          const addFieldResponse = await ratingTemplateService.addRatingTemplate(templateInfo)

          if (addFieldResponse.statusCode === 200) {
            showNotification(STATUS.SUCCESS, 'Rating Field Added successfully')
            navigate(-1)
          } else showNotification(STATUS.FAILURE, 'Rating Field Not Updated')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getratingTemplate = async () => {
    try {
      const ratingFieldResponse = await ratingTemplateService.getOneRatingTemplate(param.id)

      if (ratingFieldResponse.statusCode === 200) {
        setFieldData(ratingFieldResponse.data[0])

        setValue('templateName', ratingFieldResponse.data[0]['templateName'])
        setValue('status', ratingFieldResponse.data[0]['status'] ? 'Enable' : 'Disable')
        ratingFieldResponse.data[0]['ratings'].forEach((field: any) => {
          setValue(field.name, field.ratingFields)
        })
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }

  const getAllRatingTemplate = async () => {
    try {
      const ratingFieldResponse = await ratingFieldService.getNewRatingFields(true, 1)

      if (ratingFieldResponse.statusCode === 200) setRatingFields(ratingFieldResponse.data)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }

  useEffect(() => {
    if (param.id) {
      getratingTemplate()
    }
  }, [param])

  useEffect(() => {
    getAllRatingTemplate()
  }, [])

  return (
    <div className="inventory-add-form inven-form">
      <p className="title-content">Add Template</p>
      <div className="inventory-form ">
        <form onSubmit={handleSubmit(onSubmit)} className="tempinvert-form" style={{ marginTop: '30px' }}>
          <InputFieldWithLabels
            register={register('templateName')}
            error={errors.templateName?.type !== undefined}
            errorText={errors.templateName?.message}
            text="Template Name"
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('oneStar')}
            error={errors.oneStar?.type !== undefined}
            errorText={errors.oneStar ? errors.oneStar.message : ''}
            text="One Star"
            control={control}
            field="field"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.ratings[0]?.ratingFields}
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('twoStar')}
            error={errors.twoStar?.type !== undefined}
            errorText={errors.twoStar ? errors.twoStar.message : ''}
            text="Two Star"
            control={control}
            field="field"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.ratings[1]?.ratingFields}
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('threeStar')}
            error={errors.threeStar?.type !== undefined}
            errorText={errors.threeStar ? errors.threeStar.message : ''}
            text="Three Star"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.ratings[2]?.ratingFields}
            control={control}
            field="field"
          />
          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('fourStar')}
            error={errors.fourStar?.type !== undefined}
            errorText={errors.fourStar ? errors.fourStar.message : ''}
            text="Four Star"
            control={control}
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.ratings[3]?.ratingFields}
            field="field"
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('fiveStar')}
            error={errors.fiveStar?.type !== undefined}
            errorText={errors.fiveStar ? errors.fiveStar.message : ''}
            text="Five Star"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.ratings[4]?.ratingFields}
            control={control}
            field="field"
          />

          <MaterialDropDown
            dropdown={status_list}
            register={register('status')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Enable"
            defaultValue={fieldData?.status ? 'Enable' : 'Disable'}
            control={control}
            field="status"
          />

          <div className="form-btn-wrap">
            <ActionButton label={fieldData._id ? 'Update' : 'ADD'} type="submit" />
            <ActionButton
              color="outlined"
              label="Cancel"
              type="button"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserAddEditForm
