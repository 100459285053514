
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton'
import { useNavigate } from 'react-router-dom'
import { IReviewData } from 'services/interfaces/review.interface'
import { Autocomplete, FormControl, Pagination, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { showNotification, STATUS } from 'common/constant'
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/ApiResponse.interface'
// import { RatingFieldService } from 'services/ratingField.service'

import './styles.scss'
import { RatingTemplateService } from 'services/ratingTemplate.service'
import RatingTemplateDetails from 'containers/forms/RatingTemplateDetails'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import appContext from 'context/app.context'

const approvedList = ['Active', 'InActive']

export const TemplatePage: React.FC = () => {
  const [showReview, setshowReview] = useState<boolean>(false)
  const [reviewList, setReviewList] = useState<IReviewData[] | []>([])
  const [isEditData, setIsEditData] = useState<any>()
  const [pageCount, setPageCount] = useState<number>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortingType, setSortingType] = useState<string>('1')
  const [page, setPage] = React.useState(1)
  const [filterRating, setFilterRating] = useState<any>()
  const [filterStatus, setFilterStatus] = useState<any>('')
  const { setSpinnerVisibility } = useContext(appContext)

  const navigate = useNavigate()

  // const ratingFieldService = new RatingFieldService()
  const templateService = new RatingTemplateService()

  const getreviewList = async () => {
    try {
      setSpinnerVisibility(true)
      setPage(1)
      const fieldResponse: ISuccessResponse = (await templateService.getRatingTemplates(
        '',
        '',
        sortColumn,
        sortingType,
        1
      )) as ISuccessResponse
      if (fieldResponse.statusCode === 200) {
        setPageCount(Math.ceil(Number(fieldResponse.count) / 10))
        setReviewList(fieldResponse.data)
      }
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    let status: any = '',
      rate: any = ''
    setPage(value)
    if (filterStatus) status = filterStatus === 'Active' ? true : false

    const data = {
      status,
      rating: rate
    }
    multipleProductFilter(data, status, sortColumn, sortingType, value)
  }

  const multipleProductFilter = async (data: any, status: any, columnName?: string, sortType?: string, pages?: any) => {
    try {
      setPage(pages)
      setSpinnerVisibility(true)
      const Pimproducts: ISuccessResponse | IErrorResponse = (await templateService.getRatingTemplates(
        data,
        status,
        columnName,
        sortType,
        pages
      )) as ISuccessResponse
      const getpimproducts = Pimproducts
      setReviewList(getpimproducts.data)
      
      setPageCount(Math.ceil(Number(getpimproducts.count) / 10))
      setSpinnerVisibility(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    setSpinnerVisibility(true)

    let status: any = '',
      rate: any = ''

    if (filterStatus) status = filterStatus === 'Active' ? true : false
    if (filterRating) {
      if (filterRating === 'One') rate = 1
      else if (filterRating === 'Two') rate = 2
      else if (filterRating === 'Three') rate = 3
      else if (filterRating === 'Four') rate = 4
      else if (filterRating === 'Five') rate = 5
    }
    const data = {
      status,
      rating: rate
    }
   
    multipleProductFilter(data, status, columnName, sortType, 1)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

  const handleProductFilter = () => {
    setSpinnerVisibility(true)

    let status: any = '',
      rate: any = ''

    if (filterStatus) {
      if (filterStatus) {
        status = filterStatus === 'Active' ? true : false
      }

      if (filterRating) {
        if (filterRating === 'One') rate = 1
        else if (filterRating === 'Two') rate = 2
        else if (filterRating === 'Three') rate = 3
        else if (filterRating === 'Four') rate = 4
        else if (filterRating === 'Five') rate = 5
      }
      const data = {
        status,
        rating: rate
      }
     
      multipleProductFilter(data, status, sortColumn, sortingType, 1)
    } else {
      setSpinnerVisibility(false)
      showNotification(STATUS.FAILURE, 'Please Select Value')
    }
  }

  useEffect(() => {
    getreviewList()
  }, [])

  const getSerialNumber = (index: number) => {
    if (page === 1 && index < 9) return index + 1
    else if (index === 9) return page + '0'

    return `${page - 1}${index + 1}`
  }

  return (
    <div className="listed-product-layout">
      <div className="btn-wrapper">
        <ActionButton type="button" label="+ Add Template" onClick={() => navigate('new')} />
      </div>
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>Rating Template Table</p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <div className="filter-head ">
            <div className="filter-item">
              <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
            </div>

            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={approvedList || []}
                  renderInput={(params) => <TextField {...params} label="Status" size="small" variant="outlined" />}
                  style={{ width: 230 }}
                  value={filterStatus}
                  onChange={(e, val) => setFilterStatus(val)}
                />
              </FormControl>
            </div>

            <div className="filter-item filter-action">
              <ActionButton
                label="Apply"
                onClick={() => {
                  handleProductFilter()
                }}
              />
              <ActionButton
                label="Clear"
                onClick={() => {
                  setFilterStatus('')
                  setFilterRating('')
                  getreviewList()
                  
                }}
              />
            </div>
          </div>
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Template Name</th>
                <th>
                  Status
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'status'
                        ? (faSort as IconProp)
                        : sortingType === '-1'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('status', sortingType)}
                  />
                </th>

                <th>
                  Created Date
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'createdAt'
                        ? (faSort as IconProp)
                        : sortingType === '-1'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('createdAt', sortingType)}
                  />
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            {reviewList.length ? (
              <tbody>
                {reviewList.map((tableData: any, index: number) => {
                  return (
                    <tr key={tableData._id} className="user-list-tbody">
                      <td>{getSerialNumber(index)}</td>
                      <td>{tableData.template}</td>
                      <td>
                        {' '}
                        <span className={tableData.status ? 'status-green' : 'status-red'}>
                          {tableData.status ? 'Active' : 'InActive'}
                        </span>
                      </td>
                      <td>{moment(tableData.createdAt).format('DD-MM-YYYY hh:mm:ss A')}</td>
                      <td>
                        <div className="product-col action-btn-wrap">
                          <ActionButton
                            label="View"
                            onClick={() => {
                              setIsEditData(tableData)
                              setshowReview(true)
                            }}
                          />
                          <ActionButton label="Edit" onClick={() => navigate('edit/' + tableData._id)} />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data" colSpan={12}>
                    No data to display
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="all-products-pagination">
            <Pagination
              className="all-products-pagination"
              count={pageCount}
              shape="rounded"
              variant="outlined"
              page={page}
              onChange={handleChange}
              // defaultPage={page}
            />
          </div>
        </div>

        {showReview ? (
          <RatingTemplateDetails
            reviewData={isEditData}
            show={showReview}
            handleClose={() => {
              setshowReview(!showReview)
              getreviewList()
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
