import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'
import InputFieldWithLabels from 'components/InputFieldWithLabels'
import ActionButton from 'components/ActionButton'
import { ReviewService } from 'services/review.service'
// import { IReviewData } from "services/interfaces/review.interface";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// import { UserService } from 'services/user.service'
import { useNavigate, useParams } from 'react-router-dom'
import { showNotification, STATUS } from 'common/constant'
import RatingStars from 'components/RatingStar'
import MaterialDropDown from 'components/MaterialDropDown'
import { TextareaAutosize } from '@mui/material'

// import MaterialDropDown from 'components/MaterialDropDown'

// interface formFields extends IReviewData {

//     row_added_by: string,
//     row_added_date: string,
//     row_updated_by: string,
//     row_updated_date: string
// }

// type Tprops = {
//     reviewData: formFields
// }

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  rating: Yup.string().required('Rating is required'),
  recommended: Yup.string().required('Recommended  is required'),
  publish: Yup.string().required('Publish is required')
})
const formOptions = { resolver: yupResolver(validationSchema) }

const enable_list = ['Yes', 'No']

const ReviewAddEditForm: React.FC = () => {
  // const imgRef = useRef<any>(null)
  const [reviewData, setReviewData] = useState<any>({})
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm(formOptions)

  const reviewService = new ReviewService()
  // const userService = new UserService()
  const param = useParams()
  const navigate = useNavigate()

  const getReview = async () => {
    try {
      const reviewResponse = await reviewService.getOneReview(param.id)

      if (reviewResponse.statusCode === 200) setReviewData(reviewResponse.data)
      const fields = ['customerName', 'title', 'itemCode', 'rating', 'recommended', 'publish', 'text', 'productName']
      fields.forEach((field) => {
        if (field === 'recommended' || field === 'publish')
          reviewResponse.data[field] = reviewResponse.data[field] ? 'Yes' : 'No'

        setValue(field, reviewResponse.data[field])
      })
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Review ID is not valid')
    }
  }

  useEffect(() => {
    if (param.id) {
      getReview()
    }
  }, [param])

  const onSubmit = async (data: any) => {
    try {
      data.productId = Number(data.productId)
      data.recommended = data.recommended === 'Yes' ? 1 : 0
      data.publish = data.publish === 'Yes' ? true : false

      if (reviewData._id) {
        data._id = reviewData._id
        const updateInventoryResponse = await reviewService.updateReview(data)

        if (updateInventoryResponse.statusCode === 200) navigate(-1)
      } else {
        const addInventoryResponse = await reviewService.addReview(data)

        if (addInventoryResponse.statusCode === 200) navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const handleImageClick = () => {
  //   imgRef.current.click()
  // }

  // const handleImageChange = async (e: any) => {
  //   const imgData = new FormData()

  //   for (let i = 0; i < e.target.files.length; i++) {
  //     imgData.append('image', e.target.files[i])
  //   }

  //   const uploadResponse = await userService.updateReviewImage(imgData)

  //   console.log(uploadResponse)
  // }

  return (
    <div className="inventory-add-form">
      <p className="title-content">{reviewData._id ? 'EDIT REVIEW' : 'ADD REVIEW'}</p>
      <div className="inventory-form">
        <form className="form-review" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '30px' }}>
          <InputFieldWithLabels
            register={register('productName')}
            error={errors.productName?.type !== undefined}
            errorText={errors.productName?.message}
            text="Product"
            defaultValue={reviewData?.productName}
            disabled
          />
          <InputFieldWithLabels
            register={register('customerName')}
            error={errors.customerName?.type !== undefined}
            errorText={errors.customerName?.message}
            text="Customer"
            defaultValue={reviewData?.customerName}
            disabled
          />
          <InputFieldWithLabels
            register={register('itemCode')}
            error={errors.itemCode?.type !== undefined}
            errorText={errors.itemCode?.message}
            text="Item Code"
            defaultValue={reviewData?.itemCode}
            disabled
          />
          <InputFieldWithLabels
            register={register('title')}
            error={errors.title?.type !== undefined}
            errorText={errors.title?.message}
            text="Title"
            defaultValue={reviewData?.title}
          />
          <div className="input-label-wrapper">
            <div className="input-label">
              <label>Review</label>
              <div className="input-wrap">
                <TextareaAutosize
                  cols={50}
                  style={{ fontSize: '1rem' }}
                  autoComplete="off"
                  {...register('text')}
                  className="input-field-box-error"
                  placeholder="Enter the Review"
                  defaultValue={reviewData?.text}
                />
              </div>
            </div>
          </div>
          {/* <div className="input-label-wrapper">
            <div className="input-label">
              <label />
              <div>
                <label className="upload_label" onClick={() => handleImageClick()} />
                <input
                  onChange={handleImageChange}
                  type="file"
                  className="image_upload"
                  name="thumbnail[]"
                  id="files"
                  multiple
                  ref={imgRef}
                  accept=".jpg,.png,.jpeg"
                  title=""
                />
              </div>
            </div>
          </div> */}

          <div className="input-with-dropdown">
            <div className="label-filed-wrap">
              <label className="txt-label">Rating </label>

              <RatingStars stars={reviewData.rating} />
            </div>
          </div>


       
         
         <MaterialDropDown
            dropdown={enable_list}
            register={register('recommended')}
            error={errors.recommended?.type !== undefined}
            errorText={errors.recommended ? errors.recommended.message : ''}
            text="Recommended"
            defaultValue={reviewData?.recommended === 1 ? 'Yes' : 'No'}
            control={control}
            field="recommended"
          />

       
        
         
         <MaterialDropDown
            dropdown={enable_list}
            register={register('publish')}
            error={errors.publish?.type !== undefined}
            errorText={errors.publish ? errors.publish.message : ''}
            text="Publish"
            defaultValue={reviewData?.publish ? 'Yes' : 'No'}
            control={control}
            field="publish"
          />

        
        

          

          <div className="form-btn-wrap">
           
            <ActionButton color="outlined" label="Cancel" type="button" onClick={() => navigate(-1)} />
            <ActionButton label={reviewData._id ? 'UPDATE REVIEW' : 'ADD REVIEW'} type="submit" />
          </div>

        </form>
      </div>
    </div>
  )
}

export default ReviewAddEditForm
