import React from 'react'

import './styles.scss'

type Tprops = {
    label: any;
    color?: string;
    onClick?: () => void;
    type?: any,
    ref?:any,
    style?:any,
}

const ActionButton: React.FC<Tprops> = ({ label, color, onClick, type, ref, style }) => {
    return (
        <button style={style} ref={ref} type={type} className={color === 'outlined' ? 'btn-outline-wrap' : 'btn-color-wrap'} onClick={onClick}>{label}</button>
    )
}

export default ActionButton
