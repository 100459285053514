import React from 'react'

import './styles.scss'

type TInputFieldProps = {
    text?: string,
    error?: boolean,
    errorText?: any,
    register?: any,
    name?: string
    hint?: string,
    dropdown: string[] | []
    notrequired?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const InputWithDropDown: React.FC<TInputFieldProps> = ({ register, name, text, hint, error, errorText, dropdown, notrequired, ...props }) => {
    return (
        <div className="input-with-dropdown">
            <div className='label-filed-wrap'>
                <label className="txt-label">{text}  </label>
                <div className="input-wrap">
                    
                    <input key={text} {...register} list={text} autoComplete="false" className={error ? 'input-field-box-error' : 'input-field-box'} placeholder={text} {...props} />
                    {dropdown.length ? <datalist id={text}>
                        {dropdown.map((element: string) => {
                            return <option key={element} value={element} />
                        })}
                    </datalist> : <datalist id={text} />}

                    {error ? <p className="error-text-wrap">* {errorText}</p> : ''}

                </div>


            </div>


        </div>
    )
}

export default InputWithDropDown
