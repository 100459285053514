
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../RatingFieldViewHistory/styles.scss';

type Tprops = {
    reviewEdits?: any
    onClick?: () => void
    handleCloseUpdated?: () => void
}
const RatingFieldViewHistory: React.FC<Tprops> = ({ reviewEdits }) => {

    const newdata = reviewEdits.newData;
    const olddata = reviewEdits.oldData;
    function compareJSONObjects(obj1: any, obj2: any): any[] {
        const arr: any[] = [];
        for (const prop in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
                const myobj: any = {};
                const value1 = obj1[prop];
                const value2 = obj2[prop];
                if (Array.isArray(value1) && Array.isArray(value2)) {
                    ArrayComparison(value1, value2);
                } else if (typeof value1 === "object" && typeof value2 === "object") {
                    compareJSONObjects(value1, value2);
                } else if (value1 !== value2) {
                    myobj['Properties'] = prop;
                    myobj["current-" + prop] = value1;
                    myobj["previous-" + prop] = value2;
                    arr.push(myobj);
                }
            }
        }
        return arr;
    }

    function ArrayComparison(array1: any[], array2: any[]): void {
        array1.forEach((value, index) => {
            if (typeof value !== "string") {
                compareJSONObjects(value, array2[index]);
            }
        });
    }
    const result = compareJSONObjects(newdata, olddata);
    var styles: any = result.map((item: any) => item.Properties);

    return (
        <div className="listed-product-layoutHistory">
            <div className="Inventory-table-layoutHistory">
                <div className="Inventory-table-headerHistory">
                    <div className="listed-products-selectorHistory">
                        <p>RatingFields History</p>
                    </div>
                </div>
                <div className="Inventory-table-bodyHistory">
                    <table className="inventory-products-tableHistory">
                        <thead>
                            <tr>
                                <th style={{display:'flex', justifyContent:'start'}}>Fields Name</th>
                                <th>Previous Data</th>
                                <th>Current Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="user-list-tbodyHistory">
                                <td>
                                    <p className='textfont'>Field</p>
                                </td>
                                <td style={styles.includes('field') ? { color: styles.includes('field') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('field') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.field}
                                </td>
                                <td style={styles.includes('field') ? { color: styles.includes('field') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('field') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.field}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Status</p>
                                </td>
                                <td style={styles.includes('status') ? { color: styles.includes('status') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('status') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.status === true ? "Active" : "InActive"}
                                </td>
                                <td style={styles.includes('status') ? { color: styles.includes('status') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('status') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.status === true ? "Active" : "InActive"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default RatingFieldViewHistory