import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { FormControl } from '@mui/material'
import { Controller } from 'react-hook-form'

type TInputFieldProps = {
  field: any
  defaultValue?: any
  text?: string
  error: boolean
  errorText?: any
  register?: any
  control: any
  name?: string
  hint?: string
  dropdown: string[] | []
  notrequired?: boolean
  handleSelect?: any
  onInputChange?: (newInputValue: any) => void
  onChange?: (val: any) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const MaterialAutoComplete: React.FC<TInputFieldProps> = ({
  register,
  name,
  text,
  control,
  onInputChange,
  hint,
  error,
  errorText,
  dropdown,
  field,
  notrequired,
  defaultValue,
  handleSelect,
  ...props
}) => {
  console.log(defaultValue)

  return (
    <div className="input-with-dropdown">
      <div className="label-filed-wrap">
        <label className="txt-label mdrop">{text}</label>

        <Controller
          control={control}
          name={field}
          {...register}
          {...props}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <FormControl size="small" fullWidth>
              <Autocomplete
                onInputChange={(newInputValue) => {
                  if(onInputChange) {
                    onInputChange(newInputValue)
                  }
                }}
                onChange={(event, item) => {
                  onChange(item)
                  handleSelect(item._id)
                }}
                value={value}
                options={dropdown}
                defaultValue={defaultValue}
                getOptionLabel={(item) => (item[field] ? item[field] : '')}
                // style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={props.placeholder}
                    margin="normal"
                    variant="outlined"
                    error={!!error}
                    helperText={error ? errorText : ''}
                  />
                )}
              />
            </FormControl>
          )}
        />

        {error ? <p className="error-text-wrap">* {errorText}</p> : ''}
      </div>
    </div>
  )
}

export default MaterialAutoComplete
