import React, { useCallback, useContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import LoginBanner from 'assets/images/banner.jpg'
import LoginForm from 'containers/forms/LoginForm'
import { BASE_DOMAIN_URL, showNotification, STATUS } from 'common/constant'
import { KeycloakInstance } from 'keycloak-js'
import { LoginService } from 'services/login.service'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import appContext from 'context/app.context'

export const LoginPage: React.FC = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>()
  const loginservice = new LoginService()
  const navigate = useNavigate()
  const { setSpinnerVisibility } = useContext(appContext);

  const keycloakLogin = useCallback(() => {
    keycloak?.login({ redirectUri: `${BASE_DOMAIN_URL}/login` })
  }, [keycloak])

  const ssoLogin = async () => {
    
    const token: string = keycloak?.token ?? ''
    const logindata:any = await loginservice.ssoLogin(token)

    setSpinnerVisibility(true);

    if (logindata?.statusCode === 200) {
      setSpinnerVisibility(true);

      navigate('/dashboardLayout/admin/reviews')
    }
    else showNotification(STATUS.FAILURE, logindata?.message)
  }

  React.useEffect(() => {
    if (keycloak && keycloak?.authenticated) {
      ssoLogin()
    }
  }, [keycloak?.authenticated])

  return (
    <div className="login-page">
      <div className="content-wrapper">
        <div className="left-content">
          <LoginForm ssoInitialized={initialized} keycloakLogin={keycloakLogin} />
        </div>
        <div className="right-content">
          <img alt="login-banner" src={LoginBanner} />
        </div>
      </div>
    </div>
  )
}
