import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'
import InputFieldWithLabels from 'components/InputFieldWithLabels'
import ActionButton from 'components/ActionButton'
import MaterialDropDown from 'components/MaterialDropDown'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { RatingFieldService } from 'services/ratingField.service'
import { useNavigate, useParams } from 'react-router-dom'
import { showNotification, STATUS } from 'common/constant'

const validationSchema = Yup.object().shape({
  field: Yup.string().required('Rating Field is required').min(6, 'Password must be at least 6 characters'),
  status: Yup.string().required('Status is required')
})
const formOptions = { resolver: yupResolver(validationSchema) }

const status_list = ['Yes', 'No']
const fields = ['field', 'status', 'createdAt']

const UserAddEditForm: React.FC = () => {
  const [fieldData, setFieldData] = useState<any>({})
  const navigate = useNavigate()
  const param = useParams()

  const olddata = {
    field: fieldData.field,
    status: fieldData.status
  }

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm(formOptions)
  const ratingfieldService = new RatingFieldService()
  const [buttondisabled, setButtondisabled] = useState(false);


  const setValuesandValid = (field: any, value: any) => {
    setValue(field, value);
    const updateAddValues = {
      field: getValues().field,
      status: getValues().status
    };
    const objReview = JSON.stringify(olddata);
    const newObjReview = JSON.stringify(updateAddValues);
    setButtondisabled(objReview !== newObjReview)
  }

  const handleAddInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValuesandValid('field', e.target.value);
  };

  const onhandleAddDropChange = (e: any) => {
    setValuesandValid('status', e);
  };
  const onSubmit = async (data: any) => {
    try {
      data.row_added_by = 'developer'
      data.row_updated_by = 'developer'
      data.mobileNumber = Number(data.mobileNumber)
      data.status = data.status === 'Yes' ? true : false;

      if (fieldData._id) {
        data._id = fieldData._id
        const ratingFieldResponse = await ratingfieldService.updateRatingField(data)

        if (ratingFieldResponse.statusCode === 200) {
          showNotification(STATUS.SUCCESS, 'Rating Field Updated successfully')
          navigate(-1);
        }
        else showNotification(STATUS.FAILURE, 'Rating Field Not Updated')


      } else {
        const addFieldResponse = await ratingfieldService.addRatingField(data)

        if (addFieldResponse.statusCode === 200) {
          showNotification(STATUS.SUCCESS, 'Rating Field Added successfully')
          navigate(-1);
        }
        else showNotification(STATUS.FAILURE, 'Rating Field Not Updated')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getratingField = async () => {
    try {
      const ratingFieldResponse = await ratingfieldService.getOneRatingField(param.id)

      if (ratingFieldResponse.statusCode === 200) setFieldData(ratingFieldResponse.data)
      fields.forEach((field) => {
        if (field === 'status') ratingFieldResponse.data[field] = ratingFieldResponse.data[field] ? 'Yes' : 'No';

        setValue(field, ratingFieldResponse.data[field])
      })
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }

  useEffect(() => {
    if (param.id) {
      getratingField()
    }
  }, [param])

  return (
    <div className="inventory-add-form">
      <p className="title-content">Add Rating Field</p>
      <div className="inventory-form rating-wrp">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '30px' }}>
          
          <InputFieldWithLabels
            register={register('field')}
            error={errors.field?.type !== undefined}
            errorText={errors.field?.message}
            text="Field"
            defaultValue={fieldData?.field}
            className="field-wrp"
            onChange={handleAddInputChange}
          />

          <MaterialDropDown
            dropdown={status_list}
            register={register('status')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Enable"
            defaultValue={fieldData?.status ? 'Yes' : 'No'}
            control={control}
            field="status"
            className="status-wrp"
            onChanges={onhandleAddDropChange}
          />

          <div className="form-btn-wrap">
            <ActionButton style={!buttondisabled ? { background: "#ff8e3380", pointerEvents: 'none' } : { background: "#ff8e33" }} label={(fieldData._id ? 'Update' : 'ADD')} type="submit" />
            <ActionButton color="outlined" label="Cancel" type="button" onClick={() => {
              navigate(-1);
            }} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserAddEditForm
