import React, { useContext, useState } from 'react'
import ActionButton from 'components/ActionButton'
import { IReviewData } from 'services/interfaces/review.interface'
// import { IUserData } from 'services/interfaces/user.interface'
import RatingStars from 'components/RatingStar'
import CloseIcon from '@mui/icons-material/Close'
import './styles.scss'
import moment from 'moment'
import { ReviewService } from 'services/review.service'
import { showNotification, STATUS } from 'common/constant'
import appContext from 'context/app.context'
import { FaSpinner } from 'react-icons/fa';

type Tprops = {
  show: boolean
  reviewData: IReviewData
  handleClose: () => void
}

const ReviewDetails: React.FC<Tprops> = ({ handleClose, show, reviewData }) => {
  const { setSpinnerVisibility } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);

  const reviewService = new ReviewService()
  const handleApprove = async () => {
    setSpinnerVisibility(true)
    reviewData.publish = !reviewData.publish;
    const updateResponse = await reviewService.updateReview(reviewData)
    setIsLoading(true);

    if (updateResponse.statusCode === 200) {
      showNotification(STATUS.SUCCESS, 'Review updated Successfully')
      handleClose()
    }
    else {
      showNotification(STATUS.FAILURE, 'Review not updated')

    }
  }

  return (
    <div className="inventory-add-form">
      <div className={`Modal ${show ? 'Show' : ''}`}>
        <div className="handle-close-ic" onClick={handleClose}>
          <CloseIcon className="close-ic" />
        </div>
        <p className="title-content">Review Details</p>
        <div className="inventory-form">
          <div className="form-data">
            <span className="form-data-det">Customer </span>
            <span className="form-data-det">{reviewData.customerName}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Product ID </span>
            <span className="form-data-det">{reviewData.productName}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Title </span>
            <span className="form-data-det">{reviewData.title}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Review </span>
            <span className="form-data-det">{reviewData.text}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Recommended </span>
            <span style={{ fontWeight: 'bolder' }} className={reviewData.recommended ? "form-data-det green" : "form-data-det red"}>{reviewData?.recommended === 1 ? 'Yes' : 'No'}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Rating </span>
            <span className="form-data-det">
              <RatingStars stars={reviewData.rating} />
            </span>
          </div>

          {
            reviewData.ratings?.map((item) => (

              <div key={item.field} className="form-data">
                <span className="form-data-det" />
                <span style={{ display: 'flex', alignContent: 'space-around' }} className="form-data-det">
                  <span> {item.field} </span> <span>-</span>   <RatingStars stars={item.star} />
                </span>
              </div>

            ))
          }

          {
            reviewData.images?.map((item, index) => (

              <div key={item.image} className="form-data">
                <span className="form-data-det" >{index === 0 ? 'Images' : ''} </span>
                <span style={{ display: 'flex', alignItems: 'center' }} className="form-data-det">
                  <img style={{ marginRight: '5px' }} height='100' width='100' src={item.image} alt="Review-Img" />
                  <a href={item.image} target="_blank" rel="noopener noreferrer">View</a>

                </span>
              </div>

            ))
          }

          <div className="form-data">
            <span className="form-data-det">Created Date </span>
            <span className="form-data-det">{reviewData.createdAt ? moment(reviewData.createdAt).format('DD-MM-YYYY') : ''}</span>
          </div>
          <div className="form-btn-wrap">
            <ActionButton color="outlined" label="Close" type="button" onClick={handleClose} />
            {/* <ActionButton label={isLoading ? <FaSpinner style={{width:'90px'}} className="spinner" /> : (reviewData.publish ? 'UnApprove' : 'Approve')}  onClick={() => handleApprove()} type="submit" /> */}
            { !(reviewData.edit_status)  && (
  <ActionButton
    label={isLoading ?
      <FaSpinner style={{width:'90px'}} className="spinner" /> : 
      (reviewData.publish ? 'UnApprove' : 'Approve')
    }  
    onClick={() => handleApprove()} 
    type="submit" 
  />
)}
          </div>
        </div>
      </div>
      <div className={`Overlay ${show ? 'Show' : ''}`} />
    </div>
  )
}

export default ReviewDetails
