import React from 'react'

import './styles.scss';

type Tprops = {
    text: string,
    error?: boolean,
    errorText?: string,
    register?: any,
    icon?: any

} & React.InputHTMLAttributes<HTMLInputElement>




const InputFieldWithoutLabel: React.FC<Tprops> = ({ register, name, text, error, errorText, icon, ...props }) => {

    return (

        <div className="input-label-wrapper">
            <div className='input-img-wrapper'>
                {icon ? <img src={icon} alt='ic' /> : <></>}
                <input autoComplete='off' {...register} placeholder={text} {...props} />
            </div>
            {error ? <p className="error-text-wrap">* {errorText}</p> : ''}

        </div>


    )
}

export default InputFieldWithoutLabel
