import ActionButton from 'components/ActionButton'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IReviewData } from 'services/interfaces/review.interface'
import { Autocomplete, FormControl, Pagination, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { showNotification, STATUS } from 'common/constant'
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/ApiResponse.interface'
import { RatingFieldService } from 'services/ratingField.service'

import './styles.scss'
import RatingFieldDetails from 'containers/forms/RatingFieldDetails'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import appContext from '../../context/app.context'
const approvedList = ['Active', 'InActive']

export const RatingFieldPage: React.FC = () => {
  const [showReview, setshowReview] = useState<boolean>(false)
  const [reviewList, setReviewList] = useState<IReviewData[] | []>([])
  const [isEditData, setIsEditData] = useState<any>()
  const [pageCount, setPageCount] = useState<number>()
  // const [page, setPage] = useState<number>(1)
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortingType, setSortingType] = useState<string>('1')

  const [filterRating, setFilterRating] = useState<any>("")
  const [filterStatus, setFilterStatus] = useState<any>("")
  const { setSpinnerVisibility } = useContext(appContext)

  const navigate = useNavigate()

  const ratingFieldService = new RatingFieldService()

  const getreviewList = async () => {
    try {
      setSpinnerVisibility(true)

      // if (filterStatus) currentstatus = filterStatus === 'Active' ? true : false
      let currentstatus: any = ''
      if (filterStatus) {
        currentstatus = filterStatus === 'Active' ? true : false
      }
      const fieldResponse: ISuccessResponse = (await ratingFieldService.getRatingFields(
        '',
        currentstatus,
        sortColumn,
        sortingType,
        page
      )) as ISuccessResponse
      if (fieldResponse.statusCode === 200) {
        // setPage(1)
        // setPageCount(Math.ceil(Number(fieldResponse.count) / 10))
        setPageCount(Math.ceil(Number(fieldResponse?.count) / 10))
        const reviews = fieldResponse.data.map((review: any) => {
          review.createdAt = moment(review.createdAt).format('DD-MM-YYYY hh:ss a')
          return review
        })

        setReviewList(reviews)

        setSpinnerVisibility(false)
      }
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }


  const getSerialNumber = (index: number) => {
    if (page === 1 && index < 9) return index + 1
    else if (index === 9) return page + '0'

    return `${page - 1}${index + 1}`
  }
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    let currentstatus: any = '',
      rate: any = ''
    setPage(value)
    if (filterStatus) currentstatus = filterStatus === 'Active' ? true : false

    const data = {
      currentstatus,
      rating: rate
    }

    multipleProductFilter(data, currentstatus, sortColumn, sortingType, value)
  }

  const multipleProductFilter = async (
    data: any,
    status?: any,
    columnName?: string,
    sortType?: string,
    pages?: any
  ) => {
    try {
      let statusData: any = ''
      statusData = status === '' ? '' : status == true ? 'true' : 'false'
      setSpinnerVisibility(true)
      const Pimproducts: ISuccessResponse | IErrorResponse = (await ratingFieldService.getRatingFields(
        data,
        statusData,
        columnName,
        sortType,
        pages
      )) as ISuccessResponse
      const getpimproducts = Pimproducts
      setReviewList(getpimproducts.data)
      setPageCount(Math.ceil(Number(getpimproducts.count) / 10))
      setSpinnerVisibility(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    let currentstatus: any = '',
      rate: any = ''
    if (filterStatus) currentstatus = filterStatus === 'Active' ? true : false

    if (filterRating) {
      if (filterRating === 'One') rate = 1
      else if (filterRating === 'Two') rate = 2
      else if (filterRating === 'Three') rate = 3
      else if (filterRating === 'Four') rate = 4
      else if (filterRating === 'Five') rate = 5
    }

    const data = {
      currentstatus,
      rating: rate
    }

    multipleProductFilter(data, currentstatus, columnName, sortType, page)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

  const handleProductFilter = (data?: any) => {
    let currentstatus: any = ''
    //   rate: any = ''
    setSpinnerVisibility(true)

    if (filterStatus && data != 'clear') {
       
      if (filterStatus) currentstatus = filterStatus === 'Active' ? true : false

      const data = {
        currentstatus
      }
      setPage(1)
      multipleProductFilter(data, currentstatus, sortColumn, sortingType, 1)
      setSpinnerVisibility(false)
    } else {
      if (data === 'clear') {
        setPage(1)
        multipleProductFilter(data, '', sortColumn, sortingType, 1)
      } else {
        showNotification(STATUS.FAILURE, 'Please Select Value')
      }
      setSpinnerVisibility(false)
    }
  }

  useEffect(() => {
    getreviewList()
  }, [])


  const handleCloses = () => {

    setshowReview(!showReview)
    let currentstatus: any = '';
    if (filterStatus) currentstatus = filterStatus === 'Active' ? true : false

    multipleProductFilter("", currentstatus, sortColumn, sortingType, page)
  }

  return (
    <div className="listed-product-layout">
      <div className="btn-wrapper">
        <ActionButton type="button" label="+ Add Rating Field" onClick={() => navigate('new')} />
      </div>
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>Rating Field Table</p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <div className="filter-head ">
            <div className="filter-item">
              <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
            </div>

            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={approvedList || []}
                  renderInput={(params) => <TextField {...params} label="Status" size="small" variant="outlined" />}
                  style={{ width: 230 }}
                  value={filterStatus}
                  onChange={(e, val) => setFilterStatus(val)}
                />
              </FormControl>
            </div>

            <div className="filter-item filter-action">
              <ActionButton
                label="Apply"
                onClick={() => {
                  handleProductFilter()
                }}
              />
              <ActionButton
                label="Clear"
                onClick={() => {
                  setFilterRating('')
                  setFilterStatus('')
                  handleProductFilter('clear')
                }}
              />
            </div>
          </div>
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Field Name</th>
                <th>
                  Status
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'status'
                        ? (faSort as IconProp)
                        : sortingType === '-1'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('status', sortingType)}
                  />
                </th>
                {/* 
                <th>
                  Created Date
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={sortColumn !== 'createdAt' ? faSort as IconProp : sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                    onClick={() => sortingFunctionalityHandler('createdAt', sortingType)}
                  />
                </th> */}
                <th>
                  Updated Date
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'updatedAt'
                        ? (faSort as IconProp)
                        : sortingType === '-1'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('updatedAt', sortingType)}
                  />
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            {reviewList.length ? (
              <tbody>
                {reviewList.map((tableData: any, index: number) => {
                  return (
                    <tr key={tableData._id} className="user-list-tbody">
                      <td>{getSerialNumber(index)}</td>
                      <td>{tableData.field}</td>
                      <td>
                        {' '}
                        <span className={tableData.status ? 'status-green' : 'status-red'}>
                          {tableData.status ? 'Active' : 'InActive'}
                        </span>
                      </td>

                      {/* <td>{moment(tableData.createdAt).format('DD-MM-YYYY hh:mm:ss A')}</td> */}
                      <td>{moment(tableData.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}</td>
                      <td>
                        <div className="product-col action-btn-wrap">
                          <ActionButton
                            label="View"
                            onClick={() => {
                              setIsEditData(tableData)
                              setshowReview(true)
                            }}
                          />
                          <ActionButton label="Edit" onClick={() => navigate('edit/' + tableData._id)} />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data" colSpan={12}>
                    No data to display
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="all-products-pagination">
            <Pagination
              className="all-products-pagination"
              count={pageCount}
              // defaultPage={page} 
              shape="rounded"
              variant="outlined"
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>

        {showReview ? (
          <RatingFieldDetails
            reviewData={isEditData}
            show={showReview}
            handleClose={handleCloses}
            // handleClose={() => {
            //   setshowReview(!showReview)
            //   // multipleProductFilter(data, currentstatus, sortColumn, sortingType, page)
            //   // getreviewList()
            // }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

//to check git diff hello
