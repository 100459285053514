import React from 'react'
import ActionButton from 'components/ActionButton'
import CloseIcon from '@mui/icons-material/Close'


type Tprops = {
  show: boolean
  reviewData: any
  statusName: any
  handleClose: () => void
}

const ReviewDetails: React.FC<Tprops> = ({ statusName, handleClose, show, reviewData }) => {

  return (
    <div className="inventory-add-form">
      <div className={`Modal ${show ? 'Show' : ''}`}>
        <div className="handle-close-ic" onClick={handleClose}>
          <CloseIcon className="close-ic" />
        </div>
        <p className="title-content">Product Details</p>
        <div className="inventory-form">
        
          <div className="form-data">
            <span className="form-data-det">Product Code </span>
            <span className="form-data-det">{reviewData?.code}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Category Name</span>
            <span className="form-data-det">{reviewData?.category}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Brand Name</span>
            <span className="form-data-det">{reviewData?.brand}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Model Name </span>
            <span className="form-data-det">{reviewData?.model}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Product Name </span>
            <span className="form-data-det">{reviewData?.name}</span>
          </div>
          {/* <div className="form-data">
            <span className="form-data-det">Stock Available </span>
            <span className="form-data-det">{reviewData.availability_stock_status ? 'YES' : 'NO'}</span>
          </div> */}


          <div className="form-data">
            <span className="form-data-det">Product Status </span>
            <span className="form-data-det">{statusName(reviewData?.isActive)}</span>
          </div>


          <div className="form-btn-wrap">
            <ActionButton color="outlined" label="Close" type="button" onClick={handleClose} />
            <ActionButton label="OK" onClick={handleClose} />
          </div>
        </div>
      </div>
      <div className={`Overlay ${show ? 'Show' : ''}`} />
    </div>
  )
}

export default ReviewDetails
