import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ActionButton from 'components/ActionButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { RatingTemplateService } from 'services/ratingTemplate.service'
// import { useNavigate } from 'react-router-dom'
import { showNotification, STATUS } from 'common/constant'
import MaterialAutoComplete from 'components/MaterialAutoComplete'
import { RatingFieldService } from 'services/ratingField.service'
import _ from 'lodash'
import AutoComplete from 'components/AutoComplete'
import { AssignProductService } from 'services/assign.service'




const validationSchema = Yup.object().shape({})
const formOptions = { resolver: yupResolver(validationSchema) }

type IProps = {
  data?: any
  variant?: any
  productId?: string
  onClick?: () => void
  handleCloseUpdated?: () => void
}

const UserAddEditForm: React.FC<IProps> = ({ variant, productId, onClick, handleCloseUpdated }) => {
  const [fieldData, setFieldData] = useState<any>({})
  const [ratingFields, setRatingFields] = useState<any>({})


  // const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm(formOptions)

  const [reviewList, setReviewList] = useState([])
  const templateService = new RatingTemplateService()
  const ratingTemplateService = new RatingTemplateService()
  const ratingFieldService = new RatingFieldService()
  const assignService = new AssignProductService()

  const getTemplateList = async (template: any) => {
    try {
      if (template) {

        const { data } = await templateService.getRatingTemplates(template, 'true', '', '-1', 1, 6)
        const review = data.map((item: any) => {
          return {
            _id: item._id,
            template: item.template
          }
        })

        setReviewList(review)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProductReviewField = async (id: any) => {
    try {

      const { data } = await assignService.getProductReviewField(id)
      if (data.length > 0) {
        setFieldData(data[0])
        setValue('template', data[0]['template'])
        data[0]['starRatings'].forEach((field: any) => {
          setValue(field?.name, field?.ratingFields)
        })
      }
    } catch (error) {
      console.log('+++++++++', error)
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const templateInfo: any = {
        productId: Number(productId),
        itemCode: variant !== undefined ? variant.item_code.toString() : '',
        template: {
          _id: data.template._id,
          template: data.template.template
        },
        ratingField: [
          {
            name: 'oneStar',
            ratingFields: data.oneStar.map((item: any) => item._id)
          },
          {
            name: 'twoStar',
            ratingFields: data.twoStar.map((item: any) => item._id)
          },
          {
            name: 'threeStar',
            ratingFields: data.threeStar.map((item: any) => item._id)
          },
          {
            name: 'fourStar',
            ratingFields: data.fourStar.map((item: any) => item._id)
          },
          {
            name: 'fiveStar',
            ratingFields: data.fiveStar.map((item: any) => item._id)
          }
        ]
      }

      console.log(templateInfo)

      if (fieldData._id) {
        templateInfo._id = fieldData._id
        const ratingFieldResponse = await assignService.updateProductReviewField(templateInfo)

        if (ratingFieldResponse) {
          showNotification(STATUS.SUCCESS, 'Rating Field Updated successfully')
          handleCloseUpdated?.()
        }
      } else {
        const addFieldResponse = await assignService.assignRatingField(templateInfo)

        if (addFieldResponse.statusCode === 200) {
          showNotification(STATUS.SUCCESS, 'Rating Field Added successfully')
          handleCloseUpdated?.()
        } else showNotification(STATUS.FAILURE, 'Rating Field Not Updated')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllRatingTemplate = async () => {
    try {
      const ratingFieldResponse = await ratingFieldService.getRatingFields()

      if (ratingFieldResponse.statusCode === 200) {
        setRatingFields(ratingFieldResponse.data)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }

  const handleSelect = async (id: any) => {
    try {
      const ratingFieldResponse = await ratingTemplateService.getOneRatingTemplate(id)

      if (ratingFieldResponse.statusCode === 200) {
        setValue('status', ratingFieldResponse?.data[0]['status'])
        ratingFieldResponse?.data[0]['ratings'].forEach((field: any) => {
          setValue(field?.name, field?.ratingFields)
        })
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }
  useEffect(() => {
    getProductReviewField(fieldData._id)
    getAllRatingTemplate()
  }, [])

  return (
    <div className="inventory-add-form">
      <div className="inventory-form">
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '30px' }}>
          {variant && (
            <>
              <div className="input-with-dropdown">
                <div className="label-filed-wrap">
                  <label className="txt-label mdrop">Variant Name </label>
                  <label className="txt-label">{variant?.name} </label>
                </div>
              </div>
              <div className="input-with-dropdown" style={{ marginTop: '40px' }}>
                <div className="label-filed-wrap">
                  <label className="txt-label mdrop">Item Code </label>
                  <label className="txt-label">{variant?.item_code} </label>
                </div>
              </div>
            </>
          )}

          <AutoComplete
            register={register('template')}
            error={errors.template?.type !== undefined}
            errorText={errors.template?.message}
            text="Template Name"
            placeholder="Choose Template"
            field="template"
            control={control}
            // onChange={async (customer: any) => {
            //   alert()
            //     }}
            onInputChange={(newInputValue: any) => {
              if (newInputValue?.target?.value) {
                getTemplateList(newInputValue.target.value)
              }
            }}
            dropdown={reviewList || []}
            handleSelect={handleSelect}
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.template}
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('oneStar')}
            error={errors.oneStar?.type !== undefined}
            errorText={errors.oneStar ? errors.oneStar.message : ''}
            text="One Star"
            control={control}
            field="field"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.starRatings[0]?.ratingFields}
            disabled
          />

          <MaterialAutoComplete
            className='one-star'
            dropdown={ratingFields}
            register={register('twoStar')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Two Star"
            control={control}
            field="field"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.starRatings[1]?.ratingFields}
            disabled
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('threeStar')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Three Star"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.starRatings[2]?.ratingFields}
            control={control}
            field="field"
            disabled
          />
          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('fourStar')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Four Star"
            control={control}
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.starRatings[3]?.ratingFields}
            field="field"
            disabled
          />

          <MaterialAutoComplete
            dropdown={ratingFields}
            register={register('fiveStar')}
            error={errors.status?.type !== undefined}
            errorText={errors.status ? errors.status.message : ''}
            text="Five Star"
            defaultValue={_.isEmpty(fieldData) ? [] : fieldData?.starRatings[4]?.ratingFields}
            control={control}
            field="field"
            disabled
          />

          <div className="form-btn-wrap">
            <ActionButton label={fieldData._id ? 'Update' : 'Assign'} type="submit" />
            <ActionButton color="outlined" label="Cancel" type="button" onClick={onClick} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserAddEditForm
