import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/ApiResponse.interface'

export class AssignProductService extends BaseService {
  public async assignRatingField(userInformation: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ASSIGN_RATING_FIELDS,
        { ...userInformation },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateProductReviewField(ratingFieldInformation: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.put(
        API_ENDPOINTS.UPDATE_PRODUCT_FIELD + ratingFieldInformation._id,
        ratingFieldInformation,
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductList(
    status: string,
    pageNo: number,
    filtervalues: any,
    orderbyValues?: string,
    sortType?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    sortType = sortType ? sortType : ''
    const token = 'Bearer ' + localStorage.getItem('accessToken')

    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.GET_AlL_PRODUCT +
          `?productName=${filtervalues !== undefined ? filtervalues?.productName : ''}&model=${
            filtervalues !== undefined ? filtervalues?.model : ''
          }&sortName=${orderbyValues ? orderbyValues : ''}&productStatus=${1}&sort=${sortType}&limit=10&page=${pageNo}`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductListPimAPI(
    status?: string,
    pageNo?: number,
    sortType?: string,
    sortValue?: string,
    filtervalues?: any
  ): Promise<ISuccessResponse | IErrorResponse> {
    const { productName = '' } = filtervalues || {}

    try {
      const { data } = await this.httpClientPIM.get(
        API_ENDPOINTS.PRODUCT_LIST +
          `?code=${productName}&limit=10&page=${pageNo}&status=${status}&sortName=${sortValue}&sort=${sortType}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductVariant(id: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_PRODUCT_VARIANT + id, {
        headers: {
          Authorization: token
        }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getAssingProductHistory(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.ASSIGN_PRODUCT_HISTORY+ `/assign-product`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getAssingProductHistoryView(id:any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.ASSIGN_PRODUCT_HISTORY+ `/assign-productByid/${id}`,
        {
          headers: {
            channel: 'ONLINE',
            locale: 'en-IN'
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductVariantPIM(modelCode?: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClientPIM.get(API_ENDPOINTS.GET_PRODUCT_VARIANT_PIM + `?code=${modelCode}`, {
        headers: {
          channel: 'ONLINE',
          locale: 'en-IN'
        }
      })
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  // public async getProductReviewField(productId: any, itemCode: any): Promise<ISuccessResponse | IErrorResponse> {
  //   try {
  //     const token = 'Bearer ' + localStorage.getItem('accessToken')
  //     const { data } = await this.httpClient.get(
  //       `${API_ENDPOINTS.ASSIGN_RATING_FIELDS}?productId=${productId ? productId : ''}&&itemCode=${
  //         itemCode ? itemCode : ''
  //       }`,
  //       {
  //         headers: {
  //           Authorization: token
  //         }
  //       }
  //     )

  //     return data
  //   } catch (error) {
  //     return parseAxiosError(error as AxiosError)
  //   }
  // }

   public async getProductReviewField(id:any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.ASSIGN_RATING_FIELDS}/${id}`,
        {
          headers: {
            Authorization: token
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  
  public async deleteRatingTemplate(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.delete('inventory/deleteReview', {
        data: { erp_prod_id: code }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
