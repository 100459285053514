import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/ApiResponse.interface'
import { IUserData } from './interfaces/user.interface'

export class UserService extends BaseService {
  public async addUser(userInformation: IUserData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ADD_USER_DETAILS,
        { ...userInformation },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateUser(inventoryInformation: IUserData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.put('inventory/updateUser', inventoryInformation)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getUser(
    user?: any,
    page?: any,
    status?: any,
    sort?: any,
    limit?: any
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      console.log(page, status, sort)
      let username = user ? user : ''
      const token = 'Bearer ' + localStorage.getItem('accessToken')

      const { data } = await this.httpClient.get(
        API_ENDPOINTS.GET_All_USERS +
          `?user=${username}&limit=${limit ? limit : 10}&page=${page ? page : 1}&status=&sort=DESC`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async deletewarehouse(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.delete('inventory/deleteUser', {
        data: { erp_prod_id: code }
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateReviewImage(imgData: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.MULTIPLE_IMAGE_UPLOAD,
        { imgData },
        {
          headers: { Authorization: token }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
