import { FormControl, MenuItem, Select } from '@mui/material'
// import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

import './styles.scss'

type TInputFieldProps = {
  text?: string
  error?: boolean
  errorText?: any
  register?: any
  control?: any
  name?: string
  hint?: string
  dropdown?: any
  field?: string
  notrequired?: boolean
  defaultValue?: string
  onChanges?:(value: any)=>void
} & React.InputHTMLAttributes<HTMLInputElement>

const MaterialDropDown: React.FC<TInputFieldProps> = ({
  register,
  name,
  text,
  control,
  hint,
  error,
  errorText,
  dropdown,
  notrequired,
  defaultValue,
  field,
  onChanges,
  ...props
}) => {

  return (
    <div className="input-with-dropdown">
      <div className="label-filed-wrap">

        <label className="txt-label mdrop ">{text}</label>
        <Controller
        
          name={field}
          control={control}
          {...register}
          {...props}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <FormControl size="small" fullWidth >
              {/* <InputLabel id="demo-simple-select-helper-label">{text}</InputLabel> */}
              <Select
                 sx={{
                  border: "1px solid #ccc",
                  width:"100%"
                 }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={value}
                // label={text}
                onChange={(e)=>{
                  const newValue = e.target.value
                  onChange(newValue)
                  onChanges?.(newValue);
                }}
                defaultValue={defaultValue}
                
              >
                {dropdown?.map((item: string) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        {error ? <p className="error-text-wrap">* {errorText}</p> : ''}
      </div>
    </div>
  )
}

export default MaterialDropDown

