// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent, Pagination } from '@mui/material'
import ActionButton from 'components/ActionButton'
import React, { useEffect, useState } from 'react'
import { ISuccessResponse } from 'services/interfaces/ApiResponse.interface'
import { ReviewService } from 'services/review.service'
import './styles.scss';
import CloseIcon from '@mui/icons-material/Close'
import RatingFieldViewHistory from './RatingFieldViewHistory'
import moment from 'moment'


const RatingFieldHistory = () => {
  const [showHistory, setshowRatingHistory] = useState([])
  const reviewService = new ReviewService();
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageHistoryCount, setPageHistoryCount] = useState<number>(1);
  const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0)
  const [open, setOpen] = React.useState(false)
  const [showHistoryview, setShowHistoryview] = useState()


  const getreviewHistoryList = async () => {
    try {
      const showReviewHistory: ISuccessResponse = (await reviewService.getRatingFieldHistory(pageNo)) as ISuccessResponse

      if (showReviewHistory.statusCode === 200) {
        setshowRatingHistory(showReviewHistory.data)
        setPageHistoryCount(Math.ceil(Number(showReviewHistory.count) / 10))
        setTotalHistoryCount(showReviewHistory.count || 0)


      } else {
        console.log('showReviewHistory', showReviewHistory)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSerialNumber = (index: number) => {
    if (pageNo === 1 && index < 9)
      return index + 1
    else if (index === 9)
      return pageNo + "0"
    return `${pageNo - 1} ${index + 1}`
  }

  const getPageHistoryResult = () => {
    if (totalHistoryCount > 10 && pageNo * 10 < totalHistoryCount)
      return pageNo * 10
    return totalHistoryCount
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    getreviewHistoryList()
  }, [pageNo])

  const getreviewHistoryViewList = React.useCallback(async (id: any) => {
    try {
      const showReviewHistory: ISuccessResponse = (await reviewService.getRatingHistoryView(id)) as ISuccessResponse

      if (showReviewHistory.statusCode === 200) {
        setShowHistoryview(showReviewHistory.data)
        setOpen(true)

      } else {
        console.log('firstss', showReviewHistory)
      }
    } catch (error) {
      console.log(error)
    }
  }, [showHistoryview])

  return (
    <div className="listed-product-layout">
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>RatingFields History</p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>RatingField Name</th>
                <th>Action</th>
                <th>Modified Date</th>
              </tr>
            </thead>
            {showHistory.length ?
              (<tbody>
                {showHistory.map((ele: any, index: number) => {
                  return (
                    <tr key={ele._id} className="user-list-tbody">
                      <td>
                        {getSerialNumber(index)}
                      </td>
                      <td>
                        {ele.name}
                      </td>
                      <td>
                        {ele.title}
                      </td>
                      <td>
                        {ele.method}
                      </td>
                      <td>
                        {moment(ele.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}
                      </td>
                      <td>
                        <div className="product-col">
                          <ActionButton
                            label="View"
                            onClick={() => { getreviewHistoryViewList(ele._id) }}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}

              </tbody>)
              : (
                <tbody>
                  <tr>
                    <td className="empty-data" colSpan={12}>
                      No data to display
                    </td>
                  </tr>
                </tbody>
              )
            }

          </table>
          <div className="all-products-count">
            {' Showing Result '}
            {getPageHistoryResult()} of {totalHistoryCount}
          </div>
          <div className="all-products-pagination">
            <Pagination
              className="all-products-pagination"
              shape='rounded'
              variant='outlined'
              page={pageNo}
              count={pageHistoryCount}
              onChange={(event: any, value: number) => {
                setPageNo(value)
              }}
            />
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth
        maxWidth="lg">


        <div className="dilog-title">
          <CloseIcon onClick={handleClose} className="pointer-events" />
        </div>

        <DialogContent >
          <RatingFieldViewHistory reviewEdits={showHistoryview} />
        </DialogContent>

      </Dialog>
    </div>
  )
}

export default RatingFieldHistory