import React from "react";
// import { EToasterVariants } from "../services/types/comman.type";




const appState = {

    sideMenuVisibility: false,
    // setSideMenuVisibility: (visibility: boolean) => { },
    spinnerVisibility: false,
    setSpinnerVisibility: (_visibility: boolean) => { ''},
    toasterProps: { type: "success", message: "Success Message", visibility: false },
    // showToaster: (type: EToasterVariants, message: string) => { },
    // backDrop:false,
    // setbackDrop:(backDrop: any) => { },
    

}

export const appContext = React.createContext(appState);


export default appContext;

