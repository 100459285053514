
import React, { useState } from 'react'
import './styles.scss'
import { AccountBoxRounded } from '@mui/icons-material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'
import { store } from '../../redux/store';
import { LOGIN, USER } from '../../redux/actions/UserActionTypes'
import { Menu, MenuItem } from '@mui/material'
// import appContext from 'context/app.context'

const AppheaderRightContent: React.FC = () => {
  const [logout, setLogout] = useState<boolean>(false)
  // const { setSpinnerVisibility } = useContext(appContext);

  const navigate = useNavigate()

  const role: string | null = localStorage.getItem('role')
  const isAllowed: boolean =
    role === 'ROOT_ADMIN' ||
    role === 'PMPL_CATALOGUE_MANAGER' ||
    role === 'PMPL_OPERATIONS_MANAGER' ||
    role === 'PMPL_FINANCE_MANAGER'

  const { keycloak } = useKeycloak<KeycloakInstance>()

  const handleClose = () => {
    setLogout(false)
  }

  const logoutHandler = () => {
    localStorage.clear()
    store.dispatch({
      type: LOGIN,
      payload: []
    })
    store.dispatch({
      type: USER,
      payload: []
    })

    if (keycloak?.authenticated) {
      keycloak?.logout()
    } else {
      navigate('/login')
    }
  }

  const switchProfileHandler = () => {
    localStorage.removeItem('seller_name')
    localStorage.removeItem('sellerMail')
    navigate('/sellerLayout/admin/seller')
  }

  return (
    <div className="rignt-seller-content">
      <AccountBoxRounded className="Account-ic-header" />

      <div className="name-email-wrapper">
        <p className="header-seller-name">{localStorage.getItem('name')}</p>
        <p className="header-seller-email">{localStorage.getItem('userMail')}</p>
      </div>

      <KeyboardArrowRight className="header-down-icon" onClick={() => setLogout(true)} />
      <div className="menu-item-wrapper">
       
        <Menu anchorEl={null} className="login-menu" open={logout} onClose={handleClose} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
          {isAllowed ? <MenuItem onClick={() => switchProfileHandler()}>Switch Other Account</MenuItem> : <></>}
          <MenuItem onClick={logoutHandler} >Logout</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default AppheaderRightContent
