// import * as React from 'react'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AssignProductForm from 'containers/forms/AssignProductForm '
import { AssignProductService } from 'services/assign.service'
import { showNotification, STATUS } from 'common/constant'


interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
   
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
    
      {value === index && (
        <Box sx={{ p: 3 }}>
          
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

type Tprops = {
  reviewEdits?: any
  onClick?: () => void
  handleCloseUpdated?: () => void
}

const MaterialTab: React.FC<Tprops> = ({ reviewEdits, onClick, handleCloseUpdated }) => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [product, setProduct] = React.useState<any>([])
  const assignService = new AssignProductService()
  

  const getVariantProduct = async () => {
    try {
     
      //setSpinnerVisibility(true);
      // alert(param.id)
      const productResponse = await assignService.getProductVariantPIM(reviewEdits)
      
      if (productResponse.status === 200) {
        
        setProduct(productResponse?.data?.content || [])
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'ratingField ID is not valid')
    }
  }

  React.useEffect(() => {
    if (reviewEdits) {
      getVariantProduct()
    }
  }, [reviewEdits])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  return (
    <Box>
      {/* <div className="inventory-add-form">
        <p className="title-content">Add Template</p>
        <div className="inventory-form"></div>
      </div> */}
    
      <AppBar position="static" className="muitable-container">
     

        
        <Tabs
            
          className="muitable"
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        centered
        >
          {product.map((item: any, index: number) => {
            return <Tab  key={item.item_code} label={`Item Code : ${item.item_code}`} {...a11yProps(index + 1)} />
          })}
        </Tabs>
      

      </AppBar>
     
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
          <AssignProductForm productId={param.id} />
        </TabPanel> */}

        {product?.map((item: any, index: number) => {
          return (
            <TabPanel key={item.item_code} value={value} index={index} dir={theme.direction}>
              
              <AssignProductForm data={product} variant={item} productId={reviewEdits} onClick={onClick} handleCloseUpdated={handleCloseUpdated}/>
            </TabPanel>
          )
        })}

        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          <AssignProductForm data={product} productId={param.id} />
        </TabPanel> */}
      </SwipeableViews>
    </Box>
  )
}

export default MaterialTab
