import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import UserIcon from 'assets/icons/user.png'
import LockIcon from 'assets/icons/lock.png'
import InputFieldWithoutLabel from 'components/InputFieldWithoutLabel'
import ActionButton from 'components/ActionButton'
import PoorvikaLogo from 'assets/images/poorvika-white-bg-log.png'
import { Key } from '@mui/icons-material'
import MaterialButton from 'components/MaterialButton'
import { LoginService } from 'services/login.service'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import { showNotification, STATUS } from 'common/constant'
import appContext from 'context/app.context'

type LoginFormProps = {
  keycloakLogin: () => void
  ssoInitialized: boolean
}

type formFields = {
  userName: string
  password: string
}

const loginFormValidation = {
  userName: {
    required: { value: true, message: 'Username field is empty' },
    pattern: {
      value: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
      message: 'Please Enter Valid Mobile number'
    }
  },
  password: {
    required: { value: true, message: 'Password field is empty' },
    minLength: {
      value: 5,
      message: 'Password must be at least 5 characters long'
    }
  }
}
const LoginForm: React.FC<LoginFormProps> = ({ keycloakLogin, ssoInitialized }): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<formFields>({ mode: 'onChange' })

  const navigate = useNavigate()

  const loginservice = new LoginService()
  const { setSpinnerVisibility } = useContext(appContext)

  const onSubmit = async (data: formFields) => {
    try {
      const loginResponse: any = await loginservice.login(data)
      setSpinnerVisibility(true)

      console.log(loginResponse)
      // setSpinnerVisibility(true);

      if (loginResponse.statusCode === 200) {
        //  setSpinnerVisibility(false);
        // setSpinnerVisibility(true);
        navigate('/dashboardLayout/admin/reviews')
      } else {
        setSpinnerVisibility(false)
        showNotification(STATUS.FAILURE, loginResponse.message)
       
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="login-form-wrap">
      <div className="logo-form">
        <img alt="" src={PoorvikaLogo} />
      </div>
      <p className="sign-in-content">Sign in</p>
      <p className="access-acc-content">To access your account</p>
      <form className="login-form-wrap" onSubmit={handleSubmit(onSubmit)}>
        <InputFieldWithoutLabel
          register={register('userName', loginFormValidation.userName)}
          text="Username"
          error={errors.userName?.type !== undefined}
          errorText={errors.userName ? errors.userName.message : ''}
          icon={UserIcon}
        />
        <InputFieldWithoutLabel
          register={register('password', loginFormValidation.password)}
          error={errors.password?.type !== undefined}
          errorText={errors.password ? errors.password.message : ''}
          text="Password"
          icon={LockIcon}
          type="password"
        />

        <div className="form-btn-wrap">
          <ActionButton type="submit" label="LOGIN" />
        </div>
      </form>
      {ssoInitialized ? (
        <>
          <p>
            <span className="grey-content">(OR)</span>
          </p>

          <div className="sso-login-btn">
            <MaterialButton endIcon={<Key />} label="SSO Login" onClick={keycloakLogin} type="submit" />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default LoginForm
