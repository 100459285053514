import ActionButton from 'components/ActionButton'
import React, { useContext, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { IReviewData } from 'services/interfaces/review.interface'
import { Autocomplete, Dialog, DialogContent, FormControl, Pagination, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { showNotification, STATUS } from 'common/constant'
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/ApiResponse.interface'
import './styles.scss'
import { AssignPage } from 'pages/AssignPage'
import { AssignProductService } from 'services/assign.service'
import AssignProductDetails from 'containers/forms/AssignProductDetails'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import appContext from '../../context/app.context'
import CloseIcon from '@mui/icons-material/Close'





const approvedList = ['Enabled', 'Disabled']

export const AssignedListPage: React.FC = () => {
  const [showReview, setshowReview] = useState<boolean>(false)
  const [reviewList, setReviewList] = useState<IReviewData[] | []>([])
  const [isEditData, setIsEditData] = useState<any>()
  const [pageCount, setPageCount] = useState<number>()
  const [page, setPage] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortingType, setSortingType] = useState<string>('asc')
  const [productName, setProductName] = useState<string>('')
  const [reviewEdit, setReviewEdit] = useState<any | []>([])
  // const [reviewEditUpdated, setReviewEditUpdated] = useState<any | []>([])
  // const [model, setModel] = useState<string>('')
  // const [brand, setBrand] = useState<string>('')
  const [filterStatus, setFilterStatus] = useState<any>('')
  // console.log(reviewEdit)
  // const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const assignService = new AssignProductService()
  const { setSpinnerVisibility } = useContext(appContext)
 
  

  const getProductList = async () => {
    try {
      // alert(filterStatus)
      setSpinnerVisibility(true)
      setPage(1)
      const fieldResponse: ISuccessResponse = (await assignService.getProductListPimAPI(
        filterStatus,
        page,
        sortingType,
        sortColumn,
        filterStatus
      )) as ISuccessResponse
      if (fieldResponse.status === 200) {
        setReviewList(fieldResponse?.data?.content?.result || [])
        setPageCount(Math.ceil(Number(fieldResponse?.data?.content?.metaData?.totalRecords) / 10))
        setSpinnerVisibility(false)
      }
      setSpinnerVisibility(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
   // setSpinnerVisibility(true)
    setOpen(false)
    
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // let status: any = '';

    // if (filterStatus) status = filterStatus === 'Disabled' ? false : true
    setPage(value)
    const data = {
      productName,
      // brand,
      filterStatus
    }
    multipleProductFilter(filterStatus, value, sortingType, sortColumn, data)
  }

  const multipleProductFilter = async (
    status?: any,
    pages?: any,
    sortType?: string,
    sortColumn?: any,
    filter?: any | any
  ) => {
    try {
      setPage(pages)
      setSpinnerVisibility(true)
      const Pimproducts: ISuccessResponse | IErrorResponse = (await assignService.getProductListPimAPI(
        status,
        pages,
        sortType,
        sortColumn,
        filter
      )) as ISuccessResponse
      const getpimproducts = Pimproducts
      setReviewList(getpimproducts?.data?.content?.result || [])

      setPageCount(Math.ceil(Number(getpimproducts?.data?.content?.metaData?.totalRecords) / 10))
      setSpinnerVisibility(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      productName,
      // brand,
      filterStatus
    }
    setSortColumn(columnName)
    setSortingType(sortType === 'asc' ? 'desc' : 'asc')
    multipleProductFilter(filterStatus, page, sortingType, columnName, data)
  }

  const handleProductFilter = () => {
    setSpinnerVisibility(true)
    if (filterStatus || productName) {
      const data = {
        productName
        // brand
      }
      let status: any = ''
      if (filterStatus) status = filterStatus === 'Disabled' ? 0 : 1
      multipleProductFilter(status, page, sortingType, sortColumn, data)
    } else {
      showNotification(STATUS.FAILURE, 'Please Select Value')
      setSpinnerVisibility(false)
    }
  }

  const statusName = (status: boolean) => {
    return status ? <span className="status-green">Enabled</span> : <span className="status-red">Disabled</span>
    // if (status === 0) return <span className="status-red">Disabled</span>
    // else if (status === 1) return <span className="status-green">Enabled</span>
    // else if (status === 2) return <span className="status-orange">Invisible</span>
  }
  const getSerialNumber = (index: number) => {
    if (page === 1 && index < 9) return index + 1
    else if (index === 9) return page + '0'

    return `${page - 1}${index + 1}`
  }
  const assignProductview = () => {
    setshowReview(!showReview)
    // getProductList()
  }

  const handleCloseUpdated = () => {
    getProductList()
    setOpen(false)
  }

  useEffect(() => {
    setSpinnerVisibility(true)
    getProductList()
  }, [])

  return (
    <div className="listed-product-layout">
      <div className="Inventory-table-layout">
        <div className="Inventory-table-header">
          <div className="listed-products-selector">
            <p>Assigning Product Table</p>
          </div>
        </div>
        <div className="Inventory-table-body">
          <div className="filter-head ">
            <div className="filter-item">
              <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
            </div>

            <div className="filter-item">
              <FormControl fullWidth>
                <TextField
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                  size="small"
                  id="outlined-basic"
                  label="Product Name"
                  variant="outlined"
                />
              </FormControl>
            </div>
            {/* <div className="filter-item">
              <FormControl fullWidth>
                <TextField onChange={(e) => setBrand(e.target.value)} value={brand} size='small' id="outlined-basic" label="Brand Name" variant="outlined" />
              </FormControl>
            </div> */}

            <div className="filter-item">
              <FormControl fullWidth>
                <Autocomplete
                  getOptionLabel={(option) => option || ''}
                  id="combo-box-demo"
                  options={approvedList || []}
                  renderInput={(params) => <TextField {...params} label="Status" size="small" variant="outlined" />}
                  style={{ width: 230 }}
                  value={filterStatus}
                  onChange={(e, val) => setFilterStatus(val)}
                />
              </FormControl>
            </div>

            <div className="filter-item filter-action">
              <ActionButton
                label="Apply"
                onClick={() => {
                  handleProductFilter()
                }}
              />
              <ActionButton
                label="Clear"
                onClick={() => {
                  setFilterStatus('')
                  setProductName('')
                  getProductList()
                }}
              />
            </div>
          </div>
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>S.No</th>

                <th>
                  Brand
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'brand'
                        ? (faSort as IconProp)
                        : sortingType === 'desc'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('brand', sortingType)}
                  />
                </th>
                <th>Image</th>
                <th>
                  Product Name
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'model'
                        ? (faSort as IconProp)
                        : sortingType === 'desc'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('model', sortingType)}
                  />
                </th>
                <th>
                  Status
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={
                      sortColumn !== 'status'
                        ? (faSort as IconProp)
                        : sortingType === 'desc'
                        ? (faSortDown as IconProp)
                        : (faSortUp as IconProp)
                    }
                    onClick={() => sortingFunctionalityHandler('status', sortingType)}
                  />
                </th>

                <th>Actions</th>
              </tr>
            </thead>
            {reviewList.length ? (
              <tbody>
                {reviewList.map((tableData: any, index: number) => {
                  return (
                    <tr key={tableData.code} className="user-list-tbody">
                      <td>{getSerialNumber(index)}</td>
                      <td>{tableData?.brand}</td>
                      <td>
                        {' '}
                        <div className="product-col">
                          <div className="pimg-wrap">
                            <img alt={tableData?.name} className="product-img" src={tableData?.image} />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="pcontent-wrap">{tableData?.name}</div>
                      </td>

                      <td>{statusName(tableData?.isActive)}</td>

                      <td>
                        <div className="product-col action-btn-wrap">
                          <ActionButton
                            label="View"
                            onClick={() => {
                              setIsEditData(tableData)
                              setshowReview(true)
                            }}
                          />
                          {/* <ActionButton label="Edit" onClick={() => navigate('edit/' + tableData.model_code)} /> */}
                          <ActionButton
                            label="Assign"
                            onClick={() => {
                              setOpen(true)
                              setReviewEdit(tableData.model_code)
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data" colSpan={12}>
                    No data to display
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="all-products-pagination">
            <Pagination
              className="all-products-pagination"
              count={pageCount}
              shape="rounded"
              page={page}
              variant="outlined"
              onChange={handleChange}
            />
          </div>
        </div>

        {showReview ? (
          <AssignProductDetails
            reviewData={isEditData}
            show={showReview}
            statusName={statusName}
            handleClose={assignProductview}
          />
        ) : (
          <></>
        )}
      </div>

      <Dialog 
      onClose={handleClose} 
      open={open} 
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
      >
        <div className="model-wrp">
          <div>
            <div className="dilog-title dilog-wrp">
              {/* <div className="inner-title"><h3>Set backup account</h3></div> */}
              <CloseIcon onClick={handleClose} className="pointer-events" />
            </div>
          </div>
          <DialogContent>
            
            <AssignPage reviewEdits={reviewEdit} handleCloseUpdated={handleCloseUpdated} onClick={handleClose} />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}
