import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import './styles.scss'
import ActionButton from 'components/ActionButton'
import { IUserData } from 'services/interfaces/user.interface'
// import { useNavigate } from 'react-router-dom'

interface formFields extends IUserData {
  row_added_by: string
  row_added_date: string
  row_updated_by: string
  row_updated_date: string
}

type Tprops = {
  show: boolean
  userData: formFields
  handleClose: () => void
}

const UserAddEditForm: React.FC<Tprops> = ({ handleClose, show, userData }) => {
  // const navigate = useNavigate()

  return (
    <div className="inventory-add-form">
      <div className={`Modal ${show ? 'Show' : ''}`}>
        <div className="handle-close-ic" onClick={handleClose}>
          <CloseIcon className="close-ic" />
        </div>
        <p className="title-content">User Details</p>
        <div className="inventory-form">
          <div className="form-data">
            <span className="form-data-det">Username </span>
            <span className="form-data-det">{userData.userName}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Email </span>
            <span className="form-data-det">{userData.email}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Mobile Number </span>
            <span className="form-data-det">{userData.mobileNumber}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Role </span>
            <span className="form-data-det">{userData.role}</span>
          </div>
          <div className="form-data">
            <span className="form-data-det">Created Date </span>
            <span className="form-data-det">{moment(userData.createdAt, 'DD-MM-YYYY hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss A')}</span>
          </div>
          <div className="form-btn-wrap">
            {/* <ActionButton label="Edit" onClick={() => navigate('edit:' + userData._id)} type="submit" /> */}
            <ActionButton color="outlined" label="Close" type="button" onClick={handleClose} />
          </div>
        </div>
      </div>
      <div className={`Overlay ${show ? 'Show' : ''}`} />
    </div>
  )
}

export default UserAddEditForm
