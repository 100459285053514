
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../ReviewHistoryView/styles.scss';

type Tprops = {
    reviewEdits?: any
    onClick?: () => void
    handleCloseUpdated?: () => void

}

const ReviewHistoryView: React.FC<Tprops> = ({ reviewEdits }) => {
    const newdata = reviewEdits.newData;
    const olddata = reviewEdits.oldData;
    function compareJSONObjects(obj1: any, obj2: any): any[] {
        const arr: any[] = [];
        for (const prop in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
                const myobj: any = {};
                const value1 = obj1[prop];
                const value2 = obj2[prop];
                if (Array.isArray(value1) && Array.isArray(value2)) {
                    ArrayComparison(value1, value2);
                } else if (typeof value1 === "object" && typeof value2 === "object") {
                    compareJSONObjects(value1, value2);
                } else if (value1 !== value2) {
                    myobj['Properties'] = prop;
                    myobj["current-" + prop] = value1;
                    myobj["previous-" + prop] = value2;
                    arr.push(myobj);
                }
            }
        }
        return arr;
    }

    function ArrayComparison(array1: any[], array2: any[]): void {
        array1.forEach((value, index) => {
            if (typeof value !== "string") {
                compareJSONObjects(value, array2[index]);
            }
        });
    }
    const result = compareJSONObjects(newdata, olddata);
    var styles: any = result.map((item: any) => item.Properties);

    return (
        <div className="listed-product-layoutHistory">
            <div className="Inventory-table-layoutHistory">
                <div className="Inventory-table-headerHistory">
                    <div className="listed-products-selectorHistory">
                        <p>Review History</p>
                    </div>
                </div>
                <div className="Inventory-table-bodyHistory">
                    <table className="inventory-products-tableHistory">
                        <thead>
                            <tr>
                                <th style={{ display: 'flex', justifyContent: 'start' }}>Fields Name</th>
                                <th>Previous Data</th>
                                <th>Current Data</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr className="user-list-tbodyHistory">

                                <td>
                                    <p className='textfont'>Item Code</p>
                                </td>
                                <td style={styles.includes('itemCode') ? { color: styles.includes('itemCode') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('itemCode') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.itemCode}
                                </td>

                                <td style={styles.includes('itemCode') ? { color: styles.includes('itemCode') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('itemCode') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.itemCode}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Title</p>

                                </td>
                                <td style={styles.includes('title') ? { color: styles.includes('title') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('title') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.title}
                                </td>

                                <td style={styles.includes('title') ? { color: styles.includes('title') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('title') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.title}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Text</p>
                                </td>
                                <td style={styles.includes('text') ? { color: styles.includes('text') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('text') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.text}
                                </td>

                                <td style={styles.includes('text') ? { color: styles.includes('text') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('text') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.text}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Rating</p>
                                </td>
                                <td style={styles.includes('rating') ? { color: styles.includes('rating') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('rating') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.rating}
                                </td>
                                <td style={styles.includes('rating') ? { color: styles.includes('rating') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('rating') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.rating}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Recommended</p>
                                </td>
                                <td style={styles.includes('recommended') ? { color: styles.includes('recommended') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('recommended') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.recommended === 1 ? "Yes" : reviewEdits.oldData.recommended === 0 ? "Neutral" : "No"}
                                </td>

                                <td style={styles.includes('recommended') ? { color: styles.includes('recommended') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('recommended') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.recommended === 1 ? "Yes" : reviewEdits.oldData.recommended === 0 ? "Neutral" : "No"}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='textfont'>Publish</p>
                                </td>
                                <td style={styles.includes('publish') ? { color: styles.includes('publish') ? "red" : "gray", fontWeight: 700 } : { color: styles.includes('publish') ? "green" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.oldData.publish === true ? "Yes" : "No"}
                                </td>

                                <td style={styles.includes('publish') ? { color: styles.includes('publish') ? "green" : "gray", fontWeight: 700 } : { color: styles.includes('publish') ? "red" : "gray", fontWeight: 700 }}>
                                    {reviewEdits.newData.publish === true ? "Yes" : "No"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ReviewHistoryView